import React from 'react';
import {SelectInput} from '../BasicInputs';

interface props {
    agentId: string;
    setAgentId: (arg: string) => void;
}

function PickAgent({agentId, setAgentId}: props) {
    return (
        <div className="row">
            <div className="col mb-3">
                <SelectInput
                    option={[]}
                    val={agentId}
                    changeVal={setAgentId}
                    label="Agent ID"
                    placeholder="select Agent"
                />
            </div>
            <div className="col-lg-12 text-center">
				<button className="main-btn">
					Map user to Agent
				</button>
			</div>
        </div>
    );
}

export default PickAgent;
