import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import adminService from '../../../redux/features/admin/adminService';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import {UseDebounce} from '../../../utils/hooks';
import {DateSelect, SearchInput} from '../../../component/BasicInputs';
import {Link} from 'react-router-dom';

function AuditLogs() {
    const {token, details: data} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [word, setWord] = useState('');
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);

    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setDate(new Date().getDate() + 1)),
    );

    const debounced = UseDebounce(word);

    const filters = `/search?words=${word}&page=${page}&startDate=${startDate}&endDate=${endDate}`;

    const fetchAuditLogs = async () => {
        try {
            setLoad(true);
            let res = await adminService.getAuditLogs(token, filters);
            setLoad(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAuditLogs();
    }, [debounced]);

    const getName = (name: string) => {
        return name.replace(/ /g, '_');
    };

    return (
        <div>
            <BreadCrumbTitle title="Audit Logs" dataCount={list?.total} />
            <div className="row mb-3">
                <div className="col-lg-6 mt-4 ">
                    <SearchInput val={word} changeVal={setWord} />
                </div>
                <div className="col-lg-6 row ">
                    <div className="col-lg-4 mb-3">
                        <DateSelect
                            dateVal={startDate}
                            changeDateVal={setStartDate}
                        />
                    </div>
                    <div className="col-lg-4 mb-3">
                        <DateSelect
                            dateVal={endDate}
                            changeDateVal={setEndDate}
                        />
                    </div>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>User ID</th>
                                        <th>Full Name</th>
                                        <th>Email</th>
                                        {/* <th>Staff ID</th>
      <th>Role ID</th> */}
                                        <th>Action Performed</th>
                                        <th>Platform used</th>
                                        <th>Device IP</th>
                                        <th>Date Performed</th>
                                        <th>User KYC</th>
                                    </tr>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((l: any, i: number) => (
                                            <tr key={l.id}>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td className="link">
                                                    <Link
                                                        to={`/dashboard/customers/${getName(
                                                            `${l.full_name} `,
                                                        )}`}
                                                        state={l.user_id}>
                                                        {l.user_id}
                                                    </Link>
                                                </td>
                                                <td>{l.full_name}</td>
                                                <td>{l.email}</td>
                                                {/* <td>{l.staff_id}</td>
                                                <td>{l.role_id}</td> */}
                                                <td>{l.action_perform}</td>
                                                <td>{l.platform}</td>
                                                <td>{l.ip}</td>
                                                <td>{l.date_perform}</td>
                                                <td>{l.kyc_level}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuditLogs;
