import React, {useEffect, useState} from 'react';
import LoadTable from '../../../../component/Loaders/LoadTable';
import Paginate from '../../../../component/Paginate';
import dateFormat from 'dateformat';

import {FaFileCsv} from 'react-icons/fa6';
import {IoRefreshCircle, IoTrashBinSharp} from 'react-icons/io5';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {displayError} from '../../../../utils/errors';
import walletServices from '../../../../redux/features/wallet/walletServices';
import {useAppSelector} from '../../../../redux/hooks';




function WalletHistories() {
    const {token} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});

    //FILTERS
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    let filters = `?page=${page}&per_page=${limit} `;

    const fetchCalculatedInterest = async () => {
        try {
            setLoad(true);
            let res = await walletServices.getWalletHistories(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchCalculatedInterest();
    }, [page, limit]);

    const resetTransaction = () => {
        setPage(1);
    };

    return (
        <div>
            <BreadCrumbTitle title="Earnings" dataCount={list?.total} />
            <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                    <div className="filter">
                        <button
                            className="main-btn"
                            onClick={() => resetTransaction()}>
                            <IoRefreshCircle />
                            <span>Reset</span>
                        </button>
                        {/* <button className="main-btn secondary">
                            <FaFileCsv />
                            <span>Export CSV</span>
                        </button> */}
                    </div>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Transaction reference</th>
                                        <th>Amount</th>
                                        <th>previous balance</th>
                                        <th>user type</th>
                                        <th>created at</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list?.data?.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td>
                                                        {tr.transaction_ref}
                                                    </td>
                                                    <td>{tr.amount}</td>
                                                    <td>{tr.prev_balance}</td>
                                                    <td>{tr.user_type}</td>
                                                    <td>
                                                        {dateFormat(
                                                            tr.createdAt,
                                                            'mmm dd, yyyy | h:MM TT',
                                                        )}
                                                    </td>
                                                    <td>
                                                    <IoTrashBinSharp />
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.per_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list?.total}
                                pageSize={limit}
                                lastPage={list?.last_page}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WalletHistories;
