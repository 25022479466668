
import { authHeader } from "../../../utils/headers";
import { apiRequest } from "../../../utils/axiosInstance";

const getUserReports = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/summary-of-customer-details${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getReportSummary = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/summary-of-customer-details${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const getTransactionReport = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/status_by_months${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const getSavingsInvestmentReport = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/summary-of-customer-details${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getSubscriptionReport = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_promo_subscriptions${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};


// STATS

const getSavingsAndInvestments = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/savings_and_investment_analysis${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const getUserSavingsAnalysis = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/user_savings_analysis${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const getTotalTransactions = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/total_daily_txns${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const getAllTimeCommission = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/all_time_commissions${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};



const reportService ={
    getUserReports,
    getReportSummary,
    getTransactionReport,
    getSavingsInvestmentReport,
    getSubscriptionReport,
    getSavingsAndInvestments,
    getAllTimeCommission,
    getTotalTransactions,
    getUserSavingsAnalysis,

}

export default reportService