import React, {useState} from 'react';
import {SelectInput, TextInput} from '../BasicInputs';
import terminalService from '../../redux/features/terminals/terminalService';
import {displayError} from '../../utils/errors';
import {useAppSelector} from '../../redux/hooks';
import BasicLoad from '../Loaders/BasicLoad';
interface props {
    serialNumber: string;
    setSerialNumber: (arg: string) => void;
    manufacturer: string;
    setManufacturer: (arg: string) => void;
    model: string;
    setModel: (arg: string) => void;
    operatingSystem: string;
    setOperatingSystem: (arg: string) => void;
    upTerminal: string;
    setUpTerminal: (arg: string) => void;
    interswitchTerminal: string;
    setInterSwitchTerminal: (arg: string) => void;
    globusTerminal: string;
    setGlobusTerminal: (arg: string) => void;
    nibbsTerminal: string;
    setNibbsTerminal: (arg: string) => void;
    refresh: () => void;
}
function NewTerninal({
    serialNumber,
    setSerialNumber,
    manufacturer,
    setManufacturer,
    model,
    setModel,
    operatingSystem,
    setOperatingSystem,
    upTerminal,
    setUpTerminal,
    interswitchTerminal,
    setInterSwitchTerminal,
    globusTerminal,
    setGlobusTerminal,
    nibbsTerminal,
    setNibbsTerminal,
    refresh,
}: props) {
    const {token, details} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const payload = {
            serialNo: serialNumber,
            model,
            manufacturer,
            os: operatingSystem,
            upTid: upTerminal,
            intTid: interswitchTerminal,
            gloTid: globusTerminal,
            nibTid: nibbsTerminal,
        };
        try {
            setLoad(true);
            await terminalService.NewTerminal(token, payload);
            setLoad(false);
            refresh();
        } catch (e) {
            setLoad(false);
            displayError(e, true);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={serialNumber}
                    changeVal={setSerialNumber}
                    label="Enter serial number of the machine"
                    type="text"
                    placeholder="Serial Number"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={manufacturer}
                    changeVal={setManufacturer}
                    label="Manufacturer"
                    type="text"
                    placeholder="Manufacturer.."
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={model}
                    changeVal={setModel}
                    label="Model"
                    type="text"
                    placeholder="Model"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={[
                        {label: 'Andriod', value: 'andriod'},
                        {label: 'Linus', value: 'linus'},
                        {label: 'ROTS', value: 'rots'},
                    ]}
                    val={operatingSystem}
                    changeVal={setOperatingSystem}
                    label="Operating system of the Machine"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={upTerminal}
                    changeVal={setUpTerminal}
                    label="Up Terminal ID"
                    type="text"
                    placeholder="For unified payment"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={interswitchTerminal}
                    changeVal={setInterSwitchTerminal}
                    label="Interswitch Terminal ID"
                    type="text"
                    placeholder="For interswitch"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={globusTerminal}
                    changeVal={setGlobusTerminal}
                    label="Globus Terminal ID"
                    type="text"
                    placeholder="For Globus"
                    required
                />
            </div>

            <div className="col-lg-6 mb-3">
                <TextInput
                    val={nibbsTerminal}
                    changeVal={setNibbsTerminal}
                    label="Nibbs Terminal ID"
                    type="text"
                    placeholder="For Nibbs"
                    required
                />
            </div>
            <div onClick={handleSubmit} className="col-lg-12 text-center">
                <button className="main-btn">
                    {load ? <BasicLoad /> : 'Create'}
                </button>
            </div>
        </div>
    );
}

export default NewTerninal;
