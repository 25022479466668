 
import { authHeader } from "../../../utils/headers";
import { apiRequest } from "../../../utils/axiosInstance";

const getFees = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/fee_profiles${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};



const createFees = async (token: string, payload:any) => {
    const {data} = await apiRequest('baseUrl').post(
        `transaction/update_fee_profile`,
        payload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const feeService ={
    getFees,
    createFees
}

export default feeService