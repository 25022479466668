import React, { useState } from 'react'
import { useAppSelector } from '../../../redux/hooks';

export default function CardRequestBatch() {
    const {token} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
  return (
    <div>
      
    </div>
  )
}
