import React from 'react';

import {DateSelect, TextInput} from '../BasicInputs';

interface props {
    close: () => void;
    setOtpModal: (arg: boolean) => void;
    code: string;
    setCode: (arg: string) => void;
    totalUsers: string;
    setTotalUsers: (arg: string) => void;
    startDate: any;
    endDate: any;
    setStartDate: (arg: any) => void;
    setEndDate: (arg: any) => void;
}

function AddReferral({
    close,
    setOtpModal,
    code,
    setCode,
    totalUsers,
    setTotalUsers,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}: props) {
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={code}
                    changeVal={setCode}
                    label="Referral Code (optional)"
                    placeholder="Referral Code"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={totalUsers}
                    changeVal={setTotalUsers}
                    label="Total Users"
                    placeholder="Total Users"
                />
            </div>
            <div className="col-lg-12 mb-3">
                <DateSelect
                    dateVal={startDate}
                    changeDateVal={setStartDate}
                    label="Start Date"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <DateSelect
                    dateVal={endDate}
                    changeDateVal={setEndDate}
                    label="End Date"
                    required
                />
            </div>

            <div className="col-lg-12 text-center">
                <button
                    onClick={() => {
                        setOtpModal(true);
                        close();
                    }}
                    className="main-btn">
                    Add Referral
                </button>
            </div>
        </div>
    );
}

export default AddReferral;
