import React from 'react';
import dateFormat from 'dateformat';

function KycUpgradeRequest({kyc}: {kyc: any}) {
    const page = 1;
    const limit = 50;
    return (
        <div>
            <div className="body">
                <div className="table-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Customer Name</th>
                                    {/* <th>Document Name</th> */}
                                    <th>Status</th>
                                    <th>Present Kyc</th>
                                    <th>Proposed Kyc</th>
                                    <th>Created At</th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>

                            {kyc?.length > 0 && (
                                <tbody>
                                    {kyc.map((props: any, i:number) => (
                                        <tr key={props.id}>
                                            <td>
                                                {' '}
                                                {limit * (page - 1) + i + 1}
                                            </td>
                                            <td>
                                                {props.user.first_name.toUpperCase()}{' '}
                                                {props?.user?.last_name.toUpperCase()}
                                            </td>
                                            <td>
                                                {props?.document_url &&
                                                    props?.document?.name}
                                            </td>

                                            <td>
                                                {
                                                    props?.approval_details
                                                        ?.username
                                                }
                                                {/* {props?.approval_details?.first_name} {props?.approval_details?.last_name} */}
                                            </td>
                                            <td>
                                                {props?.approval_status.toUpperCase()}
                                            </td>
                                            <td>{props?.comment}</td>
                                            <td>
                                                {dateFormat(props?.created_at)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KycUpgradeRequest;
