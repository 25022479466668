import React from 'react';
import OTPInput from 'react-otp-input';
import BasicLoad from './Loaders/BasicLoad';

function SecurityOtp({
    otp,
    setOtp,
    load,
    handleSubmit,
}: {
    otp: any;
    setOtp: (arg: any) => void;
    load: any;
    handleSubmit: () => void;
}) {
    return (
        <div className="auth h-25">
            <div className="auth-card">
                <div style={{marginTop: '-2rem'}}></div>
                <div className="row mt-3 otpss d-flex flex-column justify-content-center">
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={props => <input {...props} />}
                        inputStyle={{
                            width: 60,
                            height: 60,
                            marginRight: 20,
                            paddingLeft: 1,
                        }}
                        inputType="number"
                    />
                    <div className="text-center mt-4">
                        {load ? (
                            <BasicLoad />
                        ) : (
                            <button
                                className="main-btn"
                                onClick={handleSubmit}>
                                Submit
                            </button>
                        )}
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    );
}

export default SecurityOtp;
