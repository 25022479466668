import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getCardBatchConfig = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(`/cards/batches`, {
        headers: authHeader(token),
    });
    return data;
};

const createCardBatchConfig = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/cards/batches`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const getCardRequests = async (token: string, filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/card_requests${filter}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const submitBatches = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_beneficiaries`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const deleteBatch = async (token: string, url: any, id: number) => {
    const {data} = await apiRequest('baseUrl').get(`/cards/${url}/${id}`, {
        headers: authHeader(token),
    });
    return data;
};

const getCardRequestBatch = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/card_requests_batches`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const createCardRequestBatch = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/card_requests_batches`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const cardService = {
    getCardBatchConfig,
    getCardRequestBatch,
    getCardRequests,
    createCardRequestBatch,
    createCardBatchConfig,
    submitBatches,
    deleteBatch,
};

export default cardService;
