import React from 'react';
import {Tab, Tabs} from 'react-bootstrap';

interface ArrProps {
    id: number;
    eventKey: string;
    title: string;
    child: React.ReactNode;
}

const TabComponent = ({
    arr,
    setKey,
    activeKey,
}: {
    arr: ArrProps[];
    setKey: (arg: string) => void;
    activeKey: string;
}) => {
    return (
        <Tabs
            id="tab-controlled"
            activeKey={activeKey}
            onSelect={k => setKey(k || '')}
            className="mb-3">
            {arr.map(tab => (
                <Tab eventKey={tab.eventKey} title={tab.title} key={tab.id}>
                    <div className="basic-card">
                        <div className="contains">{tab.child}</div>{' '}
                    </div>
                </Tab>
            ))}
        </Tabs>
    );
};

export default TabComponent;
