import React from 'react';
import {SelectInput, TextInput} from '../../../../component/BasicInputs';

interface props {
    title: any;
    setTitle: (arg: any) => void;
    userType: any;
    setUserType: (arg: any) => void;
    kycLevel: any;
    setKycLevel: (arg: any) => void;
    transactionCategory: any;
    setTransactionCategory: (arg: any) => void;
    transactionServiceCode: any;
    setTransactionServiceCode: (arg: any) => void;
    feeType: any;
    setFeeType: (arg: any) => void;
    feeCap: any;
    setFeeCap: (arg: any) => void;
    hasRange: any;
    setHasRange: (arg: any) => void;
    extraFee: any;
    setExtraFee: (arg: any) => void;
    biller: any;
    setBiller: (arg: any) => void;
    description: any;
    setDescription: (arg: any) => void;
    feeValue: any;
    setFeeValue: (arg: any) => void;
    transactionCategoryDetails:any;
    serviceCodeDetails:any;
}

function FeeForm({
    title,
    setTitle,
    userType,
    setUserType,
    kycLevel,
    setKycLevel,
    transactionCategory,
    setTransactionCategory,
    transactionServiceCode,
    setTransactionServiceCode,
    feeType,
    setFeeType,
    feeCap,
    setFeeCap,
    hasRange,
    setHasRange,
    extraFee,
    setExtraFee,
    biller,
    setBiller,
    description,
    setDescription,
    feeValue,
    setFeeValue,
    transactionCategoryDetails,
    serviceCodeDetails
}: props) {
    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={title}
                    changeVal={setTitle}
                    label="User Type"
                    type="text"
                    placeholder="User type"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={[
                        {label: 'Getripay', value: 'getripay'},
                        {label: 'Pos', value: 'pos'},
                    ]}
                    val={userType}
                    changeVal={setUserType}
                    label="User type"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <SelectInput
                    option={[
                        {label: 'User', value: 'user'},
                        {label: 'Pathner', value: 'pathner'},
                    ]}
                    val={kycLevel}
                    changeVal={setKycLevel}
                    label="KYC level"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                     option={transactionCategoryDetails?.transactionCategory?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.categoryCode},${i._id}`,
                        }),
                    )}
                    val={transactionCategory}
                    changeVal={setTransactionCategory}
                    label="Transactionn Category"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={serviceCodeDetails?.service?.map((i: any) => ({
                        label: i.serviceName,
                        value: `${i.serviceCode}`,
                    }))}
                    val={transactionServiceCode}
                    changeVal={setTransactionServiceCode}
                    label="Transaction Service Code"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={[
                        {label: 'Percentage', value: 'percentage'},
                        {label: 'Fixed', value: 'fixed'},
                    ]}
                    val={feeType}
                    changeVal={setFeeType}
                    label="Fee Type"
                    placeholder="Select"
                    required
                />
            </div>

            <div className="col-lg-4 mb-3">
                <TextInput
                    val={feeValue}
                    changeVal={setFeeValue}
                    label="User Type"
                    type="text"
                    placeholder="User type"
                    required
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={feeCap}
                    changeVal={setFeeCap}
                    label="User Type"
                    type="text"
                    placeholder="Fee Cap"
                    required
                />
            </div>

            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={[
                        {label: 'Yes', value: '0'},
                        {label: 'No', value: '1'},
                    ]}
                    val={extraFee}
                    changeVal={setExtraFee}
                    label="Extra Fee"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={[
                        {label: 'Mtn', value: 'mtn'},
                        {label: 'Glo', value: 'glo'},
                        {label: 'Airtel', value: 'airtel'},
                        {label: '9mobile', value: '9mobile'},
                        {label: 'DSTV', value: 'dstv'},
                        {label: 'GOTV', value: 'gotv'},
                        {label: 'Ikeja Eletric', value: 'ikeja electric'},

                    ]}
                    val={biller}
                    changeVal={setBiller}
                    label="Biller"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={description}
                    changeVal={setDescription}
                    label="Description"
                    type="text"
                    placeholder="Enter description here"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn">Add fee form</button>
            </div>

        </div>
    );
}

export default FeeForm;
