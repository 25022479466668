import React from 'react';
import dateFormat from 'dateformat';

interface props {
    name: String;
    description: string;
    createdAt: string;
}

function ViewAgentCategory({name, description, createdAt}: props) {
    return (
        <div className="row">
            <div className='m-3'>
                <strong>Name: </strong>
                {name}
            </div>
            <div className='m-3'>
                <strong>Description: </strong>
                {description}
            </div>
            <div className='m-3'>
                <strong>Date: </strong>
                {dateFormat(createdAt, 'mmm dd, yyyy | h:MM TT')}
            </div>
        </div>
    );
}

export default ViewAgentCategory;
