import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userDetailsType } from "../../../utils/types";

const initialState = {
	details: userDetailsType,
	token: "",
};

export const userDetails = createAsyncThunk(
	"auth/details",
	async (data: any, thunkAPI) => {
		try {
			return data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logout: (state) => {
			state.details = {};
			state.token = "";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(userDetails.fulfilled, (state, action) => {
			state.details = action.payload;
			state.token = action.payload?.token;
		});
	},
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
