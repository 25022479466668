 import { authHeader } from "../../../utils/headers";
import { apiRequest } from "../../../utils/axiosInstance";


const getWalletHistories = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/cards/wallet_histories${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const walletServices ={
    getWalletHistories
}

export default walletServices