import BreadCrumb from './BreadCrumb';

interface Props {
    title: string;
    dataCount?: number;
    button?: string;
    buttonIcon?: any;
    buttonImg?: any;
    buttonClick?: () => void;
}

const BreadCrumbTitle = ({
    title,
    dataCount,
    button,
    buttonIcon,
    buttonImg,
    buttonClick,
}: Props) => {
    return (
        <div className="title-style">
            <div className="btwn">
                <div className="title">
                    <div className="d-flex">
                        <h5>{title}</h5>
                        {dataCount ? (
                            <span className="count">{dataCount}</span>
                        ) : (
                            <></>
                        )}
                    </div>
                    <BreadCrumb />
                </div>
                {button && (
                    <button className="main-btn" onClick={buttonClick}>
                        {buttonIcon && buttonIcon}
                        {buttonImg && <img src={buttonImg} alt="Icon" />}
                        <span>{button}</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default BreadCrumbTitle;
