import {Link, useLocation, useNavigate} from 'react-router-dom';
import {MdKeyboardArrowRight, MdOutlineKeyboardBackspace} from 'react-icons/md';

const BreadCrumb = () => {
    const navigate = useNavigate();

    const location = useLocation().pathname;

    const lists = location.split('/').slice(2);

    let currentLink = '/dashboard';
    const crumbs = lists.map((crumb, index) => {
        currentLink += `/${crumb}`;

        return (
            <Link to={currentLink} key={crumb}>
                <span>{crumb}</span>
                <MdKeyboardArrowRight />
            </Link>
        );
    });

    return (
        <div className="breadcrumb-style">
            <button onClick={() => navigate(-1)}>
                <MdOutlineKeyboardBackspace />
                <span>Back</span>
            </button>
            {crumbs}
        </div>
    );
};

export default BreadCrumb;
