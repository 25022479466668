import {DateSelect, TextInput} from '../BasicInputs';

interface Props {
    startDate: any;
    setStartDate: (arg: any) => void;
    endDate: any;
    setEndDate: (arg: any) => void;
    transactionRef: string;
    setTransactionRef: (arg: string) => void;
    amount: string;
    setAmount: (arg: string) => void;
    sourceAccountNumber: string;
    setSourceAccountNumber: (arg: string) => void;
    destinationAccountNumber: string;
    setDestinationAccountNumber: (arg: string) => void;
    status: string;
    setStatus: (arg: string) => void;
    sourceAccountName: string;
    setSourceAccountName: (arg: string) => void;
    filter: () => void;
}

const BankTransfersFilters = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    transactionRef,
    setTransactionRef,
    amount,
    setAmount,
    sourceAccountNumber,
    setSourceAccountNumber,
    destinationAccountNumber,
    setDestinationAccountNumber,
    status,
    setStatus,
    sourceAccountName,
    setSourceAccountName,
    filter,
}: Props) => {
    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={startDate}
                    changeDateVal={setStartDate}
                    label="Start Date"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={endDate}
                    changeDateVal={setEndDate}
                    label="End Date"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={transactionRef}
                    changeVal={setTransactionRef}
                    label="Transaction Ref"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={amount}
                    changeVal={setAmount}
                    label="Amount"
                />
            </div>

            <div className="col-lg-6 mb-3">
                <TextInput
                    val={sourceAccountNumber}
                    changeVal={setSourceAccountNumber}
                    label="Source Account Number"
                    type="number"
                />
            </div>

            <div className="col-lg-6 mb-3">
                <TextInput
                    val={destinationAccountNumber}
                    changeVal={setDestinationAccountNumber}
                    label="Destination Account Number"
                    type='number'
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={status}
                    changeVal={setStatus}
                    label="status"
                
                />
            </div>

            <div className="col-lg-6 mb-3">
                <TextInput
                    val={sourceAccountName}
                    changeVal={setSourceAccountName}
                    label="Source Account Name"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button onClick={() => filter()} className="main-btn">
                    Filter Transactions
                </button>
            </div>
        </div>
    );
};

export default BankTransfersFilters;
