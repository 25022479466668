import axios from "axios";
import config from "../../../utils/config";
import { authHeader } from "../../../utils/headers";
import { getPayload } from "../../../utils/encrypt";
import { apiRequest } from "../../../utils/axiosInstance";

const getTransactionConfig = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_configs${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const setTransactionConfig = async (token: string, payload:any,key:any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/set_txn_limit_config`,payload,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};
const updateTransactionConfig = async (token: string, payload:any, id:any,key:any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').patch(
        `/transaction/update_txn_limit_config/${id}`,payload,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};


const getSetAboutUs = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_configs${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getPromotion = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_all_promo_configs${filters}`,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};


const getTransactionCategories = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_categories`,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};


const getServiceCode = async (token: string, id:any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/services_by_catId/${id}`,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};


const getTransactionLimit = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_types`,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};


const sendPromotion = async (token: string, payload:any) => {
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/add_promotion`,payload,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};
const updatePromotion = async (token: string, payload:any, id:any) => {
 
    const {data} = await apiRequest('baseUrl').patch(
        `/transaction/update_promo_config/${id}`,payload,
        {
            headers: authHeader(token),
        },
    );
    
    return data;
};



const getIncentiveConfig = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_limit_configs${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};



const extraService =
{
getTransactionConfig,
getTransactionCategories,
getIncentiveConfig,
getPromotion,
getSetAboutUs,
getServiceCode,
sendPromotion,
updatePromotion,
getTransactionLimit,
setTransactionConfig,
updateTransactionConfig
}

export default extraService