import {useEffect} from 'react';
import {TextInput} from '../BasicInputs';
import Select from 'react-select';

interface props {
    username: string;
    setUsername: (arg: string) => void;
    email: string;
    setEmail: (arg: string) => void;
    staffId: string;
    setStaffId: (arg: string) => void;
    password: string;
    setPassword: (arg: string) => void;
    close: () => void;
    roles: any;
    details: any;
    role: any;
    setRole: (arg: any) => void;
    setOtpModal: (arg: any) => void;
    lock: any;
    setLock: (arg: any) => void;
}

function AdminForm({
    username,
    setUsername,
    email,
    setEmail,
    staffId,
    setStaffId,
    password,
    setPassword,
    details,
    close,
    roles,
    role,
    setRole,
    setOtpModal,
    lock,
    setLock,
}: props) {

    
    useEffect(() => {
        setEmail(details?.email);
        setUsername(details?.username);
        setStaffId(details?.staff_id);
        setRole(
            details?.admin_roles?.map((i: any) => ({
                label: i.name,
                value: i.id,
            })),
        );
    }, [details.id]);

    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={email || details?.email}
                    changeVal={setEmail}
                    label="Email"
                    placeholder="Enter Email Address"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={username || details?.username}
                    changeVal={setUsername}
                    label="Username"
                    placeholder="Enter username"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={staffId || details?.staff_id}
                    changeVal={setStaffId}
                    label="Staff Id"
                    placeholder="Enter Staff Id"
                    required
                />
            </div>
            {!details?.id && (
                <div className="col-lg-12 mb-3">
                    <TextInput
                        val={password}
                        changeVal={setPassword}
                        label="Password"
                        placeholder="Enter Password"
                        required
                    />
                </div>
            )}

            <div className="basic-input mb-3">
                <label className="label">Role</label>
                <Select
                    onChange={value => setRole(value)}
                    value={role}
                    options={roles?.map((i: any) => ({
                        label: i.name,
                        value: i.id,
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={{
                        control: (base, _state) => ({
                            ...base,
                            minHeight: '35px',
                            border: '0.5px solid #000000',
                            boxShadow: 'none',
                        }),
                    }}
                />
            </div>
            {!details.id && (
                <div className="col-lg-12 mb-3">
                    <label className="label text-primary">Lock Admin?</label>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={e => {
                                setLock(!lock);
                            }}
                            checked={lock}
                        />
                    </div>
                </div>
            )}

            <div className="col-lg-12 text-center">
                <button
                    className="main-btn"
                    onClick={() => {
                        setOtpModal(true);
                        close();
                    }}>
                    {details?.id ? 'Edit' : 'Create'}
                </button>
            </div>
        </div>
    );
}

export default AdminForm;
