import React from 'react'
import { DateSelect, TextInput } from '../BasicInputs';

interface props {
    startDate: any;
    setStartDate: (arg: any) => void;
    endDate: any;
    setEndDate: (arg: any) => void;
    account: any;
    setAccount: (arg: any) => void;
    filter: () => void;
    payoutDate:any;
    setPayoutDate: (arg: any) => void;
}

function Payouts({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    account,
    setAccount,
    filter,
    payoutDate,
    setPayoutDate
}:props) {
  return (
    <div className="row">
    <div className="col-lg-6 mb-3">
        <DateSelect
            dateVal={startDate}
            changeDateVal={setStartDate}
            label="Start Date"
        />
    </div>
    <div className="col-lg-6 mb-3">
        <DateSelect
            dateVal={endDate}
            changeDateVal={setEndDate}
            label="End Date"
        />
    </div>

    <div className="col-lg-6 mb-3">
        <TextInput
            val={account}
            changeVal={setAccount}
            label="Account Number"
            placeholder='Account Number'
        />
    </div>
    <div className="col-lg-6 mb-3">
        <DateSelect
            dateVal={payoutDate}
            changeDateVal={setPayoutDate}
            label="End Date"
        />
    </div>
    <div className="col-lg-12 text-center">
        <button onClick={() => filter()} className="main-btn">
            Filter 
        </button>
    </div>
</div>
  )
}

export default Payouts