import {useEffect, useState} from 'react';
import OTPInput from 'react-otp-input';
import {displayError} from '../../utils/errors';
import authService from '../../redux/features/auth/authService';
import BasicLoad from '../Loaders/BasicLoad';
import {useAppDispatch} from '../../redux/hooks';
import {userDetails} from '../../redux/features/auth/authSlice';

const GoogleAuth = ({resp, email}: {resp: any; email: string}) => {
    const dispatch = useAppDispatch();

    const [loadingQr, setLoadQr] = useState(false);
    const [load, setLoad] = useState(false);
    const [qrDetails, setQrDetails] = useState<any>({});
    const [otp, setOtp] = useState('');

    useEffect(() => {
        if (resp.requires_setup) {
            loadQR();
        }
    }, []);

    const loadQR = async () => {
        try {
            setLoadQr(true);
            let res = await authService.loadQr({
                email,
                login_temp_token: resp.login_temp_token,
            });
            setLoadQr(false);
            setQrDetails(res);
        } catch (err) {
            setLoadQr(false);
            displayError(err, true);
        }
    };

    const verifyHandler = async (val: string) => {
        setOtp(val);
        if (val.length === 6) {
            try {
                setLoad(true);
                let res = await authService.googleAuthLogin({
                    email,
                    login_temp_token: resp.login_temp_token,
                    one_time_password: val,
                });
                setLoad(false);
                if (res) {
                    dispatch(userDetails(res));
                }
            } catch (err) {
                setLoad(false);
                displayError(err, true);
            }
        }
    };

    return (
        <div className="mt-3">
            {loadingQr ? (
                <BasicLoad />
            ) : (
                <>
                    {qrDetails?.QR_Image ? (
                        <>
                            <h6>Scan this QR Code to Complete Setup.</h6>
                            <div className="qrcode">
                                <img
                                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                                        qrDetails.QR_Image,
                                    )}`}
                                />
                            </div>
                        </>
                    ) : (
                        <h6>Enter your Google Auth Token</h6>
                    )}

                    <form className="otpss d-flex flex-column justify-content-center">
                        <OTPInput
                            value={otp}
                            onChange={verifyHandler}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={props => <input {...props} />}
                            inputStyle={{
                                width: 60,
                                height: 60,
                                marginRight: 20,
                                paddingLeft: 1,
                            }}
                            inputType="number"
                        />
                        <div className="text-center mb-4">
                            {load && <BasicLoad />}
                        </div>
                    </form>

                    <p className="text">
                        Download or Open the Google Authenticator App. Copy the
                        six digit token configured for this app and enter it
                        here.
                    </p>
                </>
            )}
        </div>
    );
};

export default GoogleAuth;
