import React from 'react';
interface props {
    lastName?: string;
    businessName?: string;
    email?: string;
    phone?: String;
    businessAddress?: string;
    cac?: string;
    addressProof: string;
    identityDocument: string;
}
function ViewAgent({
    lastName,
    businessName,
    email,
    phone,
    businessAddress,
    cac,
    addressProof,
    identityDocument,
}: props) {
    return (
        <div className="row">
            <div  className="m-3">
                <strong>Full Name: </strong>
                {lastName}
            </div>
            <div  className="m-3">
                <strong>Business Name:</strong> {businessName}
            </div>
            <div  className="m-3">
                <strong>Email:</strong> {email}
            </div>
            <div  className="m-3">
                <strong>Mobile: </strong>
                {phone}
            </div>
            <div  className="m-3">
                <strong>Address:</strong> {businessAddress}
            </div>
            <div  className="m-3">
                <strong> CAC Document: </strong>

                {!cac ? (
                    <span>No Document uploaded</span>
                ) : cac.includes('.com') ? (
                    <a href={cac} target="_blank" rel="noopener noreferrer">
                        View Document
                    </a>
                ) : (
                    <span>Invalid Document</span>
                )}
            </div>
            <div  className="m-3">
                <strong>Proof of Address: </strong>

                {!addressProof ? (
                    <span>No Document uploaded</span>
                ) : addressProof.includes('.com') ? (
                    <a
                        href={addressProof}
                        target="_blank"
                        rel="noopener noreferrer">
                        View Document
                    </a>
                ) : (
                    <span>Invalid Document</span>
                )}
            </div>
            <div  className="m-3">
                <strong> Valid Id: </strong>

                {!identityDocument ? (
                    <span>No Document uploaded</span>
                ) : identityDocument.includes('.com') ? (
                    <a
                        href={identityDocument}
                        target="_blank"
                        rel="noopener noreferrer">
                        View Document
                    </a>
                ) : (
                    <span>Invalid Document</span>
                )}
            </div>
        </div>
    );
}

export default ViewAgent;
