import React, {useEffect, useState} from 'react';
import {DateSelect, SelectInput, TextInput} from '../BasicInputs';
import extraService from '../../redux/features/extras/extraService';
import {useAppSelector} from '../../redux/hooks';
import {displayError} from '../../utils/errors';

interface props {
    name: any;
    setName: (arg: any) => void;
    description: any;
    setDescription: (arg: any) => void;
    categoryId: any;
    setCategoryId: (arg: any) => void;
    serviceCode: any;
    setServiceCode: (arg: any) => void;
    customerMaxTxnCount: any;
    setCustomerMaxTxnCount: (arg: any) => void;
    maxSubscriptionPermit: any;
    setMaxSubscriptionPermit: (arg: any) => void;
    categoryCode: any;
    setCategoryCode: (arg: any) => void;
    startDate: any;
    setStartDate: (arg: any) => void;
    endDate: any;
    setEndDate: (arg: any) => void;
    service: any;
    setService: (arg: any) => void;
    transactionCategory: any;
    serviceCodeDetails: any;
    setClosed: () => void;
    data:any
}

function PromotionConfig({
    name,
    setName,
    description,
    setDescription,
    categoryId,
    setCategoryId,
    serviceCode,
    setServiceCode,
    categoryCode,
    setCategoryCode,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    customerMaxTxnCount,
    setCustomerMaxTxnCount,
    setMaxSubscriptionPermit,
    maxSubscriptionPermit,
    service,
    setService,
    transactionCategory,
    serviceCodeDetails,
    setClosed,
    data
}: props) {
    const {token, details} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (data._id) {
            setCustomerMaxTxnCount(data?.customerMaxTxnCount);
            setMaxSubscriptionPermit(data?.maxSubscriptionPermit);
            setDescription(data?.description);
            setName(data.name);
            setCategoryId(data?.categoryId.name );
            setServiceCode(data?.serviceCode);
            setCategoryCode(data?.categoryCode);
            setStartDate(data?.startDate);
            setEndDate(data?.endDate);
            setService(data?.serviceId?.serviceName );
        }
    }, []);

    const addEditPromotion = async (e: any) => {
        e.preventDefault();
        const newData = {
            name: name,
            description: description,
            categoryCode: categoryCode.split(',')[0],
            serviceCode: serviceCode,
            serviceApplicable: service,
            customerMaxTxnCount: customerMaxTxnCount,
            maxSubscriptionPermit: maxSubscriptionPermit,
            startDate: startDate,
            endDate: endDate,
        };

        try {
            setLoad(true);
            if (data?._id) {
                await extraService.updatePromotion(token, newData, data?._id);
                setLoad(false);
                setClosed();
            } else {
                await extraService.sendPromotion(token, newData);
                setLoad(false);
                setClosed();
            }
        } catch (err) {
            displayError(err, true);
        }
    };

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={name}
                    changeVal={setName}
                    label="Promotion Name"
                    type="text"
                    placeholder="limit configuration Name"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={description}
                    changeVal={setDescription}
                    label="Promotion Description"
                    type="text"
                    placeholder="Description"
                    required
                />
            </div>

            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={transactionCategory?.transactionCategory?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.categoryCode},${i._id}`,
                        }),
                    )}
                    val={categoryCode}
                    changeVal={setCategoryCode}
                    label="Promotion Category Code"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={serviceCodeDetails?.service?.map((i: any) => ({
                        label: i.serviceName,
                        value: `${i.serviceCode}`,
                    }))}
                    val={serviceCode}
                    changeVal={setServiceCode}
                    label="Transaction Service Code"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={transactionCategory?.transactionCategory?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.categoryCode},${i._id}`,
                        }),
                    )}
                    val={categoryId}
                    changeVal={setCategoryId}
                    label="Transaction Category Applicable"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={serviceCodeDetails?.service?.map((i: any) => ({
                        label: i.serviceName,
                        value: `${i.serviceCode}`,
                    }))}
                    val={service}
                    changeVal={setService}
                    label="Service Applicable"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={maxSubscriptionPermit}
                    changeVal={setMaxSubscriptionPermit}
                    label="Maximum subscription Permit"
                    placeholder="Enter an ammount"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={customerMaxTxnCount}
                    changeVal={setCustomerMaxTxnCount}
                    label="Customers Max transaction count"
                    placeholder="Enter an ammount"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={startDate}
                    changeDateVal={setStartDate}
                    label="Start Date"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={endDate}
                    changeDateVal={setEndDate}
                    label="End Date"
                />
            </div>
            <div className="col-lg-12 text-center" onClick={addEditPromotion}>
                <button className="main-btn">
                    {load ? 'loading' : `${data?._id ? 'Edit' : 'Create'}`}
                </button>
            </div>
        </div>
    );
}

export default PromotionConfig;
