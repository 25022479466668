import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import serviceService from '../../../../redux/features/service/serviceService';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {IoRefreshCircle} from 'react-icons/io5';
import {FaFileCsv} from 'react-icons/fa6';
import {MdFilterListAlt} from 'react-icons/md';
import Paginate from '../../../../component/Paginate';
import LoadTable from '../../../../component/Loaders/LoadTable';
import Select from 'react-select';
import {displayError} from '../../../../utils/errors';
import {SearchInput, SelectInput} from '../../../../component/BasicInputs';
import {UseDebounce} from '../../../../utils/hooks';

function SwitchCode() {
    const {token} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [providers, setProviders] = useState<any>({});
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');

    // Filters
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(50);

    let filters = `?page=${page}&per_page=${limit}&provider_code_id=${filter}`;

    // let transactionFilters = `?from=${startDate}&to=${endDate}&transaction_ref=${transactionRef}&amount=${amount}&source_account_no=${sourceAccountNumber}&dest_account_no=${destinationAccountNumber}&status=${status}&source_account_name=${sourceAccountName}`;

    const fetchTransaction = async () => {
        try {
            setLoad(true);
            let res = await serviceService.getServiceCode(token, filters);
            setLoad(false);
            if (res?.data) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            displayError(err, true);
            setList([]);
        }
    };

    const fetchProviders = async () => {
        try {
            let res = await serviceService.getProviders(token, filters);
            if (res?.data) {
                setProviders(res);
            }
        } catch (err) {
            setLoad(false);
            displayError(err, true);
            setProviders([]);
        }
    };
    const debouncedSearch = UseDebounce(search);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchTransaction();
        fetchProviders();
    }, [page, limit, debouncedSearch,filter]);

    const handleSwitch = async (tr: any, data: any) => {
        if (window.confirm('Are you sure you want to switch')) {
            let payload = {
                provider_code_id: data,
            };
            try {
                const res = await serviceService.updateServiceCode(
                    token,
                    tr.id,
                    payload,
                );
                if (res) {
                    fetchTransaction();
                }
            } catch (err) {
                displayError(err, true);
            }
        }
    };

    const opt = providers?.data?.map((i: any) => ({
        label: i.name,
        value: `${i.id}`,
    }));

    return (
        <div>
            <BreadCrumbTitle
                title="Bills Transactions"
                dataCount={list?.total}
            />
            <div className="row mt-3">
                <div className="col-lg-6 d-flex align-items-center ">
                    <div className="col-lg-6 mt-4">
                        <SearchInput val={search} changeVal={setSearch} />
                    </div>
                    <div className="col-lg-1 mt-1"></div>
                    <div className="col-lg-4 mt-1">
                        <SelectInput
                            option={opt?.map((opt: any) => ({
                                label: opt.label,
                                value: opt.value,
                            }))}
                            val={filter}
                            changeVal={setFilter}
                            placeholder="Select"
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="filter">
                        <button className="main-btn" onClick={() => {}}>
                            <IoRefreshCircle />
                            <span>Reset</span>
                        </button>
                        {/* <button className="main-btn secondary">
                            <FaFileCsv />
                            <span>Export CSV</span>
                        </button> */}
                        <button className="main-btn info" onClick={() => {}}>
                            <MdFilterListAlt />
                            <span>Filter</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Service Name</th>
                                        <th>item Name </th>
                                        <th>general Name </th>
                                        <th>service provider</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>
                                                    {tr?.servicescode?.name}
                                                </td>
                                                <td>{tr?.itemscode?.name}</td>
                                                <td>{tr?.generalcode?.name}</td>
                                                <td>
                                                    {tr?.providercode?.name}
                                                </td>
                                                <td>
                                                    <div className="select">
                                                        <select
                                                            value={
                                                                tr?.providercode
                                                                    ?.id
                                                            }
                                                            onChange={e =>
                                                                handleSwitch(
                                                                    tr,
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }>
                                                            <option value={''}>
                                                                {''}
                                                            </option>
                                                            {opt &&
                                                                Array.isArray(
                                                                    opt,
                                                                ) &&
                                                                opt.map(
                                                                    (op, i) => (
                                                                        <option
                                                                            value={
                                                                                op.value
                                                                            }
                                                                            key={
                                                                                i +
                                                                                1
                                                                            }>
                                                                            {
                                                                                op.label
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SwitchCode;
