import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import terminalService from '../../../redux/features/terminals/terminalService';
import {displayError} from '../../../utils/errors';
import LoadTable from '../../../component/Loaders/LoadTable';
import {LiaEyeSolid} from 'react-icons/lia';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import ModalComponent from '../../../component/ModalComponent';
import ListingModal from '../../../component/PosBvnListing/ListingModal';
import SecurityOtp from '../../../component/SecurityOtp';
import {toast} from 'react-toastify';
import Paginate from '../../../component/Paginate';

function PosBvnListing() {
    const {token} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>([]);

    const [details, setDetails] = useState<any>([]);
    const [open, setOpen] = useState(false);

    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);

    const [otp, setOtp] = useState('');
    const [otpModal, setOtpModal] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);

    const filters = `?page=${page}&limit=${limit}`;

    const fetchBvnList = async () => {
        setLoad(true);
        try {
            const res = await terminalService.getBvnListing(token, filters);
            setList(res);
            setLoad(false);
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    const Approval = async () => {
        setOtpLoad(true);
        try {
            await terminalService.getBvnListingApproval(token, details.id, otp);
            setOtpLoad(false);
            setOtp('');
            setOtpModal(false);
            toast.success('Approved');
            fetchBvnList();
        } catch (err) {
            displayError(err, true);
            setOtpLoad(false);
            setOtp('');
        }
    };

    useEffect(() => {
        fetchBvnList();
        window.scrollTo(0, 0);
    }, [filters]);

    return (
        <div>
            <BreadCrumbTitle
                title="Pos Bvn Approval"
                dataCount={list?.total}
            />
            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>UserName</th>
                                        <th>Address</th>
                                        <th>Phone Number</th>
                                        <th>KYC Level</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    {' '}
                                                    {limit * (page - 1) + i + 1}
                                                </td>

                                                <td>
                                                    {tr.user?.first_name}{' '}
                                                    {tr.user?.last_name}
                                                </td>
                                                <td>
                                                    {tr.user?.address.substr(
                                                        0,
                                                        20,
                                                    )}
                                                </td>
                                                <td>{tr.user?.phone_number}</td>
                                                <td>
                                                    {
                                                        tr.user?.kyc_level
                                                            ?.description
                                                    }
                                                </td>
                                                <td>
                                                    {dateFormat(tr?.created_at)}
                                                </td>
                                                <td className="d-flex justify-content-evenly">
                                                    <span
                                                        role="button"
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setDetails(tr);
                                                        }}>
                                                        {' '}
                                                        <LiaEyeSolid />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                    </div>
                </div>
                {load && <LoadTable />}
            </div>

            <ModalComponent
                open={open}
                close={() => setOpen(false)}
                size="lg"
                title="Bvn Document Details">
                <ListingModal
                    details={details}
                    submit={() => {
                        setOtpModal(true);
                        setOpen(false);
                    }}
                />
            </ModalComponent>

            <ModalComponent
                open={otpModal}
                title="Enter Google Auth Code"
                close={() => setOtpModal(false)}>
                <SecurityOtp
                    otp={otp}
                    setOtp={setOtp}
                    load={otpLoad}
                    handleSubmit={() => Approval()}
                />
            </ModalComponent>
        </div>
    );
}

export default PosBvnListing;
