import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import basicSlice from "./features/basic/basicSlice";

const rootReducer = combineReducers({
	auth: authSlice,
	basic: basicSlice,
});

export default rootReducer;
