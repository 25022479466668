import React from 'react';
import dateFormat from 'dateformat';
interface props {
    level_name: string;
    description: string;
    level: String;
    created_at: string;
}

function KycLevelDetails({level_name, description, level, created_at}: props) {
    return (
        <div className="row">
            <div className="m-3">
                <strong>Level Name: </strong>
                {level_name}
            </div>
            <div className="m-3">
                <strong>Level Type: </strong>
                {level}
            </div>
            <div className="m-3">
                <strong>Description: </strong>
                {description}
            </div>
            <div className="m-3">
                <strong>Time Created: </strong>
                {dateFormat(created_at, 'h:MM TT')}
            </div>

            <div className="m-3">
                <strong>Date Created: </strong>
                {dateFormat(created_at, 'd MM, yyyy')}
            </div>
        </div>
    );
}

export default KycLevelDetails;
