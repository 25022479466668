import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getBankTransferTransactions = async (token: string, filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/bank_transfer_transactions${filter}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getBankTransferTransactionsFilter = async (
    token: string,
    filter: any,
) => {
    const {data} = await apiRequest('baseUrl').get(
        `/bank_transfer_transactions${filter}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getBillTransactions = async (token: string, filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/biller/biller_transaction${filter}`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};

const getServiceCode = async (token: string, filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/biller/switch_codes${filter}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getProviders = async (token: string, filter: any) => {
    const {data} = await apiRequest('baseUrl').get(`/biller/providers`, {
        headers: authHeader(token),
    });
    return data;
};

const updateServiceCode = async (token: string, id: number, payload: any) => {
    const {data} = await apiRequest('baseUrl').put(
        `/biller/switch_codes/${id}`,
        payload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getNibbsService = async (token: string, filter: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/${filter}`,

        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const requery = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transfers/requery_transaction
`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const serviceService = {
    getBankTransferTransactions,
    getBankTransferTransactionsFilter,
    getBillTransactions,
    updateServiceCode,
    getNibbsService,
    getProviders,
    getServiceCode,
    requery,
};

export default serviceService;
