import React from 'react';

function NinVerificationDetails({
    details,
    load,
}: {
    details?: any;
    load?: boolean;
}) {
    const data = details?.nin_data?.data;
    return (
        <div className="row">
            <div className="col-lg-6 customer-details">
                {data ? (
                    Object.entries(data).map(([key, value]: any) => {
                        if (key === 'photo' || key === 'signature') {
                            const imageUrl = `data:image/jpeg;base64,${value}`;
                            return (
                                <div key={key} className="customer-details-veri">
                                    <span className='left'>{key}</span>
                                    <img className='right' src={imageUrl} alt={key} style={{ width: '100px', height: '100px' }} />
                                </div>
                            );
                        }
                        if (key === 'nin') {
                            return (
                                <div key={key} className="customer-details-veri">
                                    <span className='left'>{key}</span>
                                    <strong className='right'>{value?.substring(0,4)}*******</strong>
                                </div>
                            );
                        }

                        return (
                            <div key={key} className="customer-details-veri">
                                <span className='left'>{key}</span>
                              
                                <strong className='right'>{value}</strong>
                            </div>
                        );
                    })
                ) : (
                    <div>No Data</div>
                )}
            </div>
            <div className="col-lg-6"></div>
        </div>
    );
}

export default NinVerificationDetails;
