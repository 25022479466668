import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import {displayError} from '../../../utils/errors';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {IoRefreshCircle} from 'react-icons/io5';
import {FaFileCsv} from 'react-icons/fa6';
import {MdFilterListAlt} from 'react-icons/md';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';
import {BiPencil} from 'react-icons/bi';
import customerServices from '../../../redux/features/customer/customerService';
import ModalComponent from '../../../component/ModalComponent';
import Filters from '../../../component/RequestNewAccount/Filter';
import SecurityOtp from '../../../component/SecurityOtp';
import ToggleCustomer from '../../../component/Customer/ToggleCustomer';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';

function RequestNewAccount() {
    const {token, details} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [approval, setApproval] = useState(false);
    const [data, setData] = useState<any>({});

    const [otp, setOtp] = useState('');
    const [otpModal, setOtpModal] = useState(false);
    const [otpLoad, setOtpLoad] = useState(false);

    const [comment, setComment] = useState('');

    //Filters
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [transactionRef, setTransactionRef] = useState('');
    const [amount, setAmount] = useState('');
    const [debitedAccount, setDebitedAccount] = useState('');
    const [narration, setNarration] = useState('');

    let filters = `?page=${page}&per_page=${limit}&from=${startDate}&to=${endDate}&transaction_ref=${transactionRef}&amount=${amount} `;

    const getAllAccounts = async () => {
        setLoad(true);
        try {
            const res = await customerServices.newAccount(token, filters);
            if (res) {
                setList(res);
                setLoad(false);
            }
        } catch (err) {
            displayError(err, true);
            setLoad(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getAllAccounts();
    }, [page, limit, applyFilter]);

    const handleSubmit = async () => {
        setOtpLoad(true);
        let payload = {
            comment,
            request_id: data,
            admin_id: details.id,
            google_auth_code: otp,
        };
        try {
            const res = await customerServices.approveRequest(token, payload, {
                aes_key: details.aes_key,
                aes_iv: details.aes_iv,
            });
            if (res) {
                setOtpLoad(false);
                getAllAccounts();
                setOtpModal(false);
                toast.success('Approved');
                setComment('');
            }
        } catch (err) {
            displayError(err, true);
            setLoad(false);
            setOtpLoad(false);
            setOtp('');
        }
    };

    const getName = (name: string) => {
        return name.replace(/ /g, '_');
    };

    return (
        <div>
            <BreadCrumbTitle
                title=" New Account Request "
                dataCount={list?.meta?.total}
            />

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <th>S/N</th>
                                    <th>Account Name</th>
                                    <th className="mr-3">Primary Account</th>
                                    <th>Account Type</th>
                                    <th>Date Requested</th>

                                    <th>Approval Status</th>
                                    <th>Purpose</th>
                                    <th>Approval Date</th>
                                    <th>Approved by</th>
                                    <th>Comment</th>
                                    <th>Action</th>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list?.data?.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td className="link">
                                                        <Link
                                                            to={`/dashboard/customers/${getName(
                                                                `${tr.first_name} ${tr.last_name}`,
                                                            )}`}
                                                            state={tr.user_id}>
                                                            {tr.first_name}{' '}
                                                            {tr.last_name} -{' '}
                                                            {tr.account_name}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {tr.primary_account}
                                                    </td>
                                                    <td>{tr.account_type}</td>
                                                    <td>
                                                        {dateFormat(
                                                            tr.created_at,
                                                        )}
                                                    </td>
                                                    <td>
                                                        {tr.approved
                                                            ? 'Approved'
                                                            : 'Pending approval'}
                                                    </td>
                                                    <td>{tr.purpose}</td>
                                                    <td>{tr.approve_date}</td>
                                                    <td>{tr.admin_id}</td>

                                                    <td>{tr.comment}</td>
                                                    <td>
                                                        <i
                                                            style={{
                                                                padding:
                                                                    '0 .25em',
                                                            }}
                                                            onClick={() => {
                                                                setApproval(
                                                                    true,
                                                                );
                                                                setData(tr.id);
                                                            }}>
                                                            <BiPencil />
                                                        </i>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.meta?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list?.meta?.total}
                                pageSize={limit}
                                lastPage={list?.meta?.last_page}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}

                        <ModalComponent
                            open={openFilter}
                            close={() => setOpenFilter(false)}
                            title="Filter New Account">
                            <Filters
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                transactionRef={transactionRef}
                                setTransactionRef={setTransactionRef}
                                amount={amount}
                                setAmount={setAmount}
                                debitedAccount={debitedAccount}
                                setDebitedAccount={setDebitedAccount}
                                narration={narration}
                                setNarration={setNarration}
                                filter={() => {
                                    setApplyFilter(!applyFilter);
                                    setOpenFilter(false);
                                }}
                            />
                        </ModalComponent>
                    </div>
                    <ModalComponent
                        open={approval}
                        close={() => setApproval(false)}
                        title="Approve Account">
                        <ToggleCustomer
                            close={() => {
                                setApproval(false);
                            }}
                            comment={comment}
                            setComment={setComment}
                            setOtpModal={setOtpModal}
                        />
                    </ModalComponent>

                    {/* Reset Google Auth */}
                    <ModalComponent
                        open={otpModal}
                        title="Enter Google Auth Code"
                        close={() => setOtpModal(false)}>
                        <SecurityOtp
                            otp={otp}
                            setOtp={setOtp}
                            load={otpLoad}
                            handleSubmit={() => {
                                handleSubmit();
                            }}
                        />
                    </ModalComponent>
                </div>
            </div>
        </div>
    );
}

export default RequestNewAccount;
