import {DateSelect, SelectInput, TextInput} from '../BasicInputs';

interface Props {
    startDate: any;
    setStartDate: (arg: any) => void;
    endDate: any;
    setEndDate: (arg: any) => void;
    transactionRef: string;
    setTransactionRef: (arg: string) => void;
    channel: string;
    setChannel: (arg: string) => void;
    debitedAccount: string;
    setDebitedAccount: (arg: string) => void;
    transactionAmount: string;
    setTransactionAmount: (arg: string) => void;
    narration: string;
    setNarration: (arg: string) => void;
    transactionType: string;
    setTransactionType: (arg: string) => void;
    serviceCode: string;
    setServiceCode: (arg: string) => void;
    setRecipient: (aeg: string) => void;
    recipient: any;
    processor: string;
    setProcessor: (arg: string) => void;
    filter: () => void;
    transactionStatus: string;
    setTransactionStatus: (arg: string) => void;
    transService: any;
}

const Filters = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    transactionRef,
    setTransactionRef,
    channel,
    setChannel,
    debitedAccount,
    setDebitedAccount,
    transactionAmount,
    setTransactionAmount,
    narration,
    setNarration,
    transactionType,
    setTransactionType,
    serviceCode,
    setServiceCode,
    recipient,
    setRecipient,
    processor,
    setProcessor,
    transactionStatus,
    setTransactionStatus,
    filter,
    transService,
}: Props) => {
    return (
        <div className="row">
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={startDate}
                    changeVal={setStartDate}
                    type="datetime-local"
                    placeholder="Enter Date"
					label='Start Date'
                />
            </div>
            <div className="col-lg-4 mb-3">
			<TextInput
                    val={endDate}
                    changeVal={setEndDate}
                    type="datetime-local"
                    placeholder="Enter Date"
					label='End Date'
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={[
                        {label: 'Pending', value: 'TS_PENDING'},
                        {label: 'Paid', value: 'TS_PAID'},
                        {label: 'Completed', value: 'TS_COMPLETED'},
                        {label: 'Failed', value: 'TS_FAILED'},
                        {label: 'Failed Failed', value: 'FAIL'},
                    ]}
                    val={transactionStatus}
                    changeVal={setTransactionStatus}
                    label="Transaction Status"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={transactionRef}
                    changeVal={setTransactionRef}
                    label="Transaction Ref"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={debitedAccount}
                    changeVal={setDebitedAccount}
                    label="Debited Account"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={recipient}
                    changeVal={setRecipient}
                    label="Recipient Number"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={[
                        {label: 'CREDIT', value: 'CREDIT'},
                        {label: 'DEBIT', value: 'DEBIT'},
                    ]}
                    val={transactionType}
                    changeVal={setTransactionType}
                    label="Transaction Type"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={transService?.map((i: any) => ({
                        label: i.serviceName,
                        value: `${i.serviceCode}`,
                    }))}
                    val={serviceCode}
                    changeVal={setServiceCode}
                    label="Service"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={transactionAmount}
                    changeVal={setTransactionAmount}
                    label="Transaction Amount"
                    type="number"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={[
                        {label: 'Web', value: 'web'},
                        {label: 'Mobile', value: 'mobile'},
                        {label: 'POS', value: 'pos'},
                    ]}
                    val={channel}
                    changeVal={setChannel}
                    label="Channel"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={[
                        {label: 'Unified Payment', value: 'UP'},
                        {label: 'Interswitch', value: 'INT'},
                        {label: 'NIBBS', value: 'NIBBS'},
                        {label: 'Providus', value: 'PVDS'},
                        {label: 'Zenith', value: 'ZENITH'},
                        {label: 'Coral Pah', value: 'CRP'},
                        {label: 'NIP', value: 'NIP'},
                        {label: 'NIP Mini', value: 'NIP_MINI'},
                    ]}
                    val={processor}
                    changeVal={setProcessor}
                    label="Processor"
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={narration}
                    changeVal={setNarration}
                    label="Narration"
                />
            </div>
            <div className="col-lg-12 text-center">
                <button onClick={() => filter()} className="main-btn">
                    Filter Transactions
                </button>
            </div>
        </div>
    );
};

export default Filters;
