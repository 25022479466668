
import {authHeader} from '../../../utils/headers';
import { apiRequest } from '../../../utils/axiosInstance';

const getCalculatedInterest = async (token: string, filters:string) => {
    const {data} = await apiRequest('url').get(
        `/interests/interest/history${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getEarnings = async (token: string, filters:string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/interest/earnings${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getInterestPayouts = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/interest/payouts${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getFilterInterestPayouts = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/interest/payouts${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};


const getInterestPayoutsConfig = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/interests/payouts/config${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};




const InterestService = {
    getEarnings,
    getInterestPayouts,
    getCalculatedInterest,
    getInterestPayoutsConfig,
    getFilterInterestPayouts
};
export default InterestService;
