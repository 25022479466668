interface UserDetailsType {
    id: number;
	username:string;
    aes_key:string;
    aes_iv:String;
    email:string;
    role_id:string;
}

const userDetailsType: UserDetailsType | Record<string, never> = {};

export {userDetailsType};

export interface Dispute  {
    disputeId: number;
    comment: string;
    addedBy: number;
    name: string;
    userType: string;
	
};
