import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import Paginate from '../../../component/Paginate';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {Link, useNavigate} from 'react-router-dom';
import LoadTable from '../../../component/Loaders/LoadTable';
import customerServices from '../../../redux/features/customer/customerService';

const CorporateAccount = () => {
    const {token} = useAppSelector(state => state.auth);

    const navigate = useNavigate();

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    let filters = `?page=${page}&per_page=${limit}`;

    const fetchAccounts = async () => {
        try {
            setLoad(true);
            let res = await customerServices.corporateAccount(token, filters);
            setLoad(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAccounts();
    }, [page, limit]);

    return (
        <>
            <BreadCrumbTitle
                title="Bank Transfer Transactions"
                dataCount={list?.total}
                button="+ New"
                buttonClick={() => navigate('/dashboard/corporate-account/new')}
            />

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>s/n</th>
                                        <th>Business Name</th>
                                        <th>Business Phone</th>
                                        <th>Business Email</th>
                                        <th>Director</th>
                                        <th>Admin</th>
                                        <th>Initiator</th>
                                        <th>Authorizer</th>
                                        <th>More</th>
                                    </tr>
                                </thead>
                                {!load && list?.length > 0 && (
                                    <tbody>
                                        {list.map((l: any, i: number) => (
                                            <tr key={l.id}>
                                                <td>
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>{l.business_name}</td>
                                                <td>{l.business_phone}</td>
                                                <td>{l.business_email}</td>
                                                <td>{l.director_name}</td>
                                                <td>
                                                    {l.admin?.first_name}{' '}
                                                    {l.admin?.last_name}
                                                </td>
                                                <td>
                                                    {l.initiator?.first_name}{' '}
                                                    {l.initiator?.last_name}
                                                </td>
                                                <td>
                                                    {l.authorizer?.first_name}{' '}
                                                    {l.authorizer?.last_name}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/dashboard/corporate-account/${l.id}`}
                                                        state={l}>
                                                        View all details
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.last_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CorporateAccount;
