import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import { apiRequest } from '../../../utils/axiosInstance';

const getLevels = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(`/user/kyc_levels`, {
        headers: authHeader(token),
    });
    return data.data;
};

const setKycLevels = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/user/kyc_levels`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getKycDetails = async (token: string,id:number) => {
    const {data} = await apiRequest('baseUrl').get(`user/user_documents?user_id=${id}`, {
        headers: authHeader(token),
    });
    return data.data;
};



const updateKycLevels = async (
    token: string,
    payload: any,
    id: any,
    key: any,
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').patch(
        `/user/kyc_levels/${id}`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getUploadedDocuments = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/user_documents${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getUpgradeRequest = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/kyc_upgrade_request${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const approveRequest = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/user/admin/user_document/approve_multiple`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};


const approveSingleRequest = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/user/admin/kyc_upgrade_request/approve`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const KYCService = {
    getUpgradeRequest,
    getUploadedDocuments,
    getLevels,
    setKycLevels,
    updateKycLevels,
    approveRequest,
    getKycDetails,
    approveSingleRequest,
};
export default KYCService;
