import {authHeader} from '../../../utils/headers';
import { getPayload } from '../../../utils/encrypt';
import { apiRequest } from '../../../utils/axiosInstance';

const loadTransactions = async (token: string, filters: string) => {
    const {data} = await  apiRequest('baseUrl').get(
        `/transaction/get_all_txns${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const transService = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_txn_services`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getTransactionDetails = async (token: string, id: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_tnx/${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const updateTransactionStatus = async (token: string, payload: any,key:any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/update_status_multiple`,
        payload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const reverseTransactionStatus = async (
    token: string,
    payload: any,
    status: any,
    key:any
) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const state =
        status === 'single'
            ? '/acc_transaction/reverse'
            : '/transfers/reverse_multiple';

    const {data} = await apiRequest('baseUrl').post(`${state}`, passPayload, {
        headers: authHeader(token),
    });
    return data.data;
};

const transactionService = {
    loadTransactions,
    getTransactionDetails,
    transService,
    updateTransactionStatus,
	reverseTransactionStatus
};

export default transactionService;
