import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import KYCService from '../../../redux/features/kyc/kycService';
import {displayError} from '../../../utils/errors';
import dateFormat from 'dateformat';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import {UseDebounce} from '../../../utils/hooks';
import {SearchInput, SelectInput} from '../../../component/BasicInputs';
import {IoRefreshCircle} from 'react-icons/io5';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import ModalComponent from '../../../component/ModalComponent';
import KycDetails from '../../../component/Kyc/KycDetails';

function UpgradeRequest() {
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const {token} = useAppSelector(state => state.auth);
    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [state, setState] = useState('');

    //Kyc Modal

    const [kycModal, setKycModal] = useState(false);
    const [kycId, setKycId] = useState(0);

    const debouncedSearch = UseDebounce(search);

    const filters = `${
        state === 'name'
            ? `?user_name=${search}&paginate=yes&no_of_pages=15`
            : state === 'filter'
            ? `?filter_by=${status}&paginate`
            : `?paginate=${page}&fliter_by=${status}&limit=${limit}`
    }`;

    const fetchRequest = async () => {
        try {
            setLoad(true);
            const res = await KYCService.getUpgradeRequest(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (debouncedSearch) {
            fetchRequest();
        } else {
            fetchRequest();
        }
    }, [page, status, limit, debouncedSearch]);

    const resetUsers = () => {
        setPage(1);
        setLimit(0);
        setSearch('');
        setStatus('');
        setState('');
        fetchRequest();
    };

    return (
        <div>
            <BreadCrumbTitle title="Upgrade Request" dataCount={list?.total} />
            <div className="d-flex align-items-center justify-content-between">
                <div className="col-lg-4 mb-4">
                    <SelectInput
                        option={[
                            {label: 'Status', value: 'filter'},
                            {label: 'Username', value: 'name'},
                        ]}
                        val={state}
                        changeVal={setState}
                        placeholder="Select Status"
                    />
                </div>
                {state === 'name' && (
                    <div className="col-lg-4">
                        <SearchInput val={search} changeVal={setSearch} />
                    </div>
                )}

                {state === 'filter' && (
                    <div className="col-lg-4 mb-4">
                        <SelectInput
                            option={[
                                {label: 'Approved', value: 'Approved'},
                                {label: 'Rejected', value: 'Rejected'},
                                {label: 'Pending', value: 'Pending'},
                            ]}
                            val={status}
                            changeVal={setStatus}
                            placeholder="Select Status"
                        />
                    </div>
                )}

                <div className="filter">
                    <button onClick={() => resetUsers()} className="main-btn">
                        <IoRefreshCircle />
                        <span>Refresh</span>
                    </button>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Customer Name</th>
                                        <th>Status</th>
                                        <th>Present KYC</th>
                                        <th>Proposed KYC</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                {!load && list?.data?.length > 0 && (
                                    <tbody>
                                        {list.data.map((tr: any, i: number) => (
                                            <tr key={tr.id}>
                                                <td>
                                                    {' '}
                                                    {limit * (page - 1) + i + 1}
                                                </td>

                                                <td
                                                    className="text-primary"
                                                    onClick={() => {
                                                        setKycModal(true);
                                                        setKycId(tr);
                                                    }}>
                                                    {tr.user.first_name}{' '}
                                                    {tr.user.last_name}
                                                </td>
                                                <td>{tr.approval_status}</td>
                                                <td>
                                                    {
                                                        tr.current_level
                                                            ?.level_name
                                                    }
                                                </td>
                                                <td>
                                                    {tr.new_level?.level_name}
                                                </td>
                                                <td>
                                                    {dateFormat(tr?.created_at)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.per_page > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.total}
                                pageSize={limit}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
            <ModalComponent
                size="lg"
                title="Kyc Upgrade Details"
                open={kycModal}
                close={() => setKycModal(false)}>
                <KycDetails props={kycId} close={() => setKycModal(false)} />
            </ModalComponent>
        </div>
    );
}

export default UpgradeRequest;
