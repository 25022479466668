import fall from '../../assets/images/fulllogo.svg'


const PreLoader = () => {
    return (
        <div className="suspense">
            <img src={fall}  alt="Loading..." />
        </div>
    );
};

export default PreLoader;
