import React, {useEffect, useState} from 'react';
import {SelectInput, TextInput} from '../BasicInputs';
import {displayError} from '../../utils/errors';
import extraService from '../../redux/features/extras/extraService';
import {useAppSelector} from '../../redux/hooks';

interface props {
    name: any;
    setName: (arg: any) => void;
    description: any;
    setDescription: (arg: any) => void;
    transactionCategory: any;
    setTransactionCategory: (arg: any) => void;
    transactionServiceCode: any;
    setTransactionServiceCode: (arg: any) => void;
    limitType: any;
    setLimitType: (arg: any) => void;
    // limit: any;
    // setLimit: (arg: any) => void;
    type: any;
    setType: (arg: any) => void;
    userCategory: any;
    setUserCategory: (arg: any) => void;
    transactionCategoryList: any;
    serviceCodeDetails: any;
    limitDetails: any;
    closed: () => void;
    limitValue: any;
    setLimitValue: (arg: any) => void;
}

function TransactionLimitConfig({
    name,
    type,
    setName,
    setType,
    limitType,
    description,
    setLimitType,
    userCategory,
    setDescription,
    setUserCategory,
    serviceCodeDetails,
    transactionCategory,
    setTransactionCategory,
    transactionServiceCode,
    transactionCategoryList,
    setTransactionServiceCode,
    limitDetails,
    closed,
    limitValue,
    setLimitValue,
}: props) {
    const {token, details} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const newData = {
            limitName: name,
            description,
            transactionServiceCode,
            transactionCategoryCode: transactionCategory.split(',')[0],
            kycLevel: type,
            limitType,
            limit: limitValue,
            userCategory,
        };
        try {
            setLoad(true);

            await extraService.setTransactionConfig(token, newData, {
                aes_key: details.aes_key,
                aes_iv: details.aes_iv,
            });
            setLoad(false);
            closed();
            reset();
        } catch (err) {
            displayError(err, true);
        }
    };

    const reset = () => {
        setName('');
        setLimitValue('');
        setLimitType('');
        setDescription('');
        setTransactionCategory('');
        setTransactionServiceCode('');
    };

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={name}
                    changeVal={setName}
                    label="Limit Configuration Name"
                    type="text"
                    placeholder="limit configuration Name"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={description}
                    changeVal={setDescription}
                    label="Limit Description"
                    type="text"
                    placeholder="Description"
                    required
                />
            </div>

            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={transactionCategoryList?.transactionCategory?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.categoryCode},${i._id}`,
                        }),
                    )}
                    val={transactionCategory}
                    changeVal={setTransactionCategory}
                    label="Transaction Category"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={serviceCodeDetails?.service?.map((i: any) => ({
                        label: i.serviceName,
                        value: `${i.serviceCode}`,
                    }))}
                    val={transactionServiceCode}
                    changeVal={setTransactionServiceCode}
                    label="Transaction Service Code"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={limitDetails?.transactionLimitType?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.tnxLimitTypeCode}`,
                        }),
                    )}
                    val={limitType}
                    changeVal={setLimitType}
                    label="Limit Type"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={limitValue}
                    changeVal={setLimitValue}
                    label="Limit Value"
                    type="text"
                    placeholder="Limit Value"
                    required
                />
            </div>
            <div className="col mb-3">
                <SelectInput
                    option={[
                        {label: 'kyc', value: 'kyc'},
                        {label: 'User Category', value: 'user_category'},
                    ]}
                    val={type}
                    changeVal={setType}
                    label="Type"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col mb-3">
                <SelectInput
                    option={[
                        {label: 'plan 1', value: 'plan_1'},
                        {label: 'plan 2', value: 'plan_2'},
                    ]}
                    val={userCategory}
                    changeVal={setUserCategory}
                    label="User Category"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button onClick={handleSubmit} className="main-btn">
                    {load ? 'loading' : `Create`}
                </button>
            </div>
        </div>
    );
}

export default TransactionLimitConfig;
