import React from 'react';
import dateFormat from 'dateformat';
import Paginate from '../../Paginate';
import LoadTable from '../../Loaders/LoadTable';

interface props {
    login: any;
    page: number;
    setPage: (arg: number) => void;
    limit: number;
    setLimit: (arg: number) => void;
    load:boolean
}

const LoginHistory = ({login, page, setPage, limit, setLimit,load}: props) => {
    return (
        <div>
            <div className="body">
                <div className="table-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Platform</th>
                                    <th>Device</th>
                                    <th>IP Address</th>
                                    <th>Date Logged In</th>
                                    <th>Channel</th>
                                </tr>
                            </thead>

                            {!load && login?.data?.length > 0 && (
                                <tbody>
                                    {login?.data?.map(
                                        (props: any, i: number) => (
                                            <tr key={props.id}>
                                                <td>
                                                    {' '}
                                                    {limit * (page - 1) + i + 1}
                                                </td>
                                                <td>{props.platform}</td>
                                                <td>{props.device}</td>
                                                <td>{props.ip}</td>
                                                <td>
                                                    {dateFormat(
                                                        props.created_at,
                                                    )}
                                                </td>
                                                <td>{props.channel}</td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {!load && login?.last_page > 1 && (
                        <Paginate
                            currentPage={page}
                            totalCount={login.total}
                            pageSize={limit}
                            onSelect={(p: number) => setPage(Number(p))}
                            onNext={(p: number) => setPage(p)}
                            onPrev={(p: number) => setPage(p)}
                            changeLimit={(p: string) => setLimit(Number(p))}
                        />
                    )}
                    {load && <LoadTable/>}
                </div>
            </div>
        </div>
    );
};

export default LoginHistory;
