import React from 'react';
import {SelectInput, TextInput} from '../BasicInputs';

interface props {
    name: string;
    setName: (arg: string) => void;
    description: string;
    setDescription: (arg: String) => void;
    childCategory: string;
    setChildCategory: (arg: String) => void;
}

function AgentCategory({
    name,
    setName,
    description,
    setDescription,
    childCategory,
    setChildCategory,
}: props) {
    return (
        <div className="row">
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={name}
                    changeVal={setName}
                    label="Name"
                    type="text"
                    placeholder="Name"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={description}
                    changeVal={setDescription}
                    label="Description"
                    type="text"
                    placeholder="Description"
                    required
                />
            </div>
            <div className="col mb-3">
                <SelectInput
                    option={[
                        {label: 'plan 1', value: 'plan_1'},
                        {label: 'plan 2', value: 'plan_2'},
                    ]}
                    val={childCategory}
                    changeVal={setChildCategory}
                    label="Operating system of the Machine"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn">Create</button>
            </div>
        </div>
    );
}

export default AgentCategory;
