import React, {useState} from 'react';
import dateFormat from 'dateformat';

const Documents = ({documents}: {documents: any}) => {
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const page = 1;
    const limit = 50;
    return (
        <div>
            <div className="body">
                <div className="table-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Customer Name</th>
                                    <th>Document Name</th>
                                    <th>Document URL</th>
                                    <th>Approved By</th>
                                    <th>Document Status</th>
                                    <th>Comment</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>

                            {documents?.length > 0 && (
                                <tbody>
                                    {documents.map((props: any, i: any) => (
                                        <tr key={props.id}>
                                            <td>
                                                {' '}
                                                {limit * (page - 1) + i + 1}
                                            </td>
                                            <td>
                                                {props.user.first_name.toUpperCase()}{' '}
                                                {props?.user?.last_name.toUpperCase()}
                                            </td>
                                            <td>
                                                {props?.document_url &&
                                                    props?.document?.name}
                                            </td>
                                            <td>
                                                {props?.document_url &&
                                                props?.document_url.substr(
                                                    0,
                                                    4,
                                                ) === 'http' ? (
                                                    <button
                                                        onClick={() =>
                                                            openInNewTab(
                                                                props?.document_url,
                                                            )
                                                        }
                                                        className="btn btn-primary">
                                                        View
                                                    </button>
                                                ) : props?.document_url &&
                                                  props?.document?.name ===
                                                      'BVN' ? (
                                                    <p>
                                                        {props?.document_url &&
                                                            props?.document_url.substr(
                                                                0,
                                                                4,
                                                            )}
                                                        *******
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {props?.document_url &&
                                                            props?.document_url}
                                                    </p>
                                                )}
                                            </td>
                                            <td>
                                                {
                                                    props?.approval_details
                                                        ?.username
                                                }
                                                {/* {props?.approval_details?.first_name} {props?.approval_details?.last_name} */}
                                            </td>
                                            <td>
                                                {props?.approval_status.toUpperCase()}
                                            </td>
                                            <td>{props?.comment}</td>
                                            <td>
                                                {dateFormat(props?.created_at)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documents;
