import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const getReferrals = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl').get(
        `/user/referral_codes${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const createReferrals = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/user/generate_referral_code`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getAuditLogs = async (token: string, filters: any) => {
    
    const {data} = await apiRequest('url').post(
        `/audit/audit_log${filters}`,
        {},
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const getAdmins = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/admin/users${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const getAdminRoles = async (token: string, filters: any) => {
    const {data} = await apiRequest('baseUrl3Admin').get(
        `/admin_roles${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const createAdmin = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/create/admin_users`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const editAdmin = async (token: string, payload: any, id: number, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').put(
        `/admin_users/${id}`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const toggleAdmin = async (
    token: string,
    status: any,
    userId: any,
    otp: any,
) => {
    let url =
        status !== '0'
            ? `enable/admin_users/${userId}`
            : `disable/admin_users/${userId}`;
    const {data} = await apiRequest('baseUrl3Admin').get(`/${url}`, {
        headers: authHeader(token, otp),
    });
    return data.data;
};
const deleteAdmin = async (token: string, id: number, otp: any) => {
    const {data} = await apiRequest('baseUrl3Admin').delete(
        `/admin_users/${id}`,
        {
            headers: authHeader(token, otp),
        },
    );
    return data.data;
};
const resetPassword = async (token: string, payload:any, id:number, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').post(
        `/admin/password/reset/${id}`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const resetGoogleAuth = async (token: string, id: number, otp: any,key:any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl3Admin').get(
        `admin_users/${id}/reset_ga`,
        {
            headers: authHeader(token,otp),
        },
    );
    return data.data;
};




const adminService = {
    getReferrals,
    createReferrals,
    getAuditLogs,
    getAdminRoles,
    getAdmins,
    createAdmin,
    toggleAdmin,
    deleteAdmin,
    editAdmin,
    resetPassword,
    resetGoogleAuth,
};

export default adminService;
