import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../redux/hooks';
import savingsService from '../../../redux/features/savings/savingsService';
import {displayError} from '../../../utils/errors';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import {SearchInput} from '../../../component/BasicInputs';
import Paginate from '../../../component/Paginate';
import LoadTable from '../../../component/Loaders/LoadTable';
import dateFormat from 'dateformat';

function SavingsWallet() {
    const {token} = useAppSelector(state => state.auth);

    //filters
    const [list, setList] = useState<any>([]);
    const [load, setLoad] = useState(false);
    const [search, setSearch] = useState('');
    const [page,setPage]= useState(1);
    const [limit,setLimit] = useState(15)

    const filters=''

    const fetchSavinsWallet = async () => {
        try {
            setLoad(true);
            const res = await savingsService.getSavingsWallet(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        fetchSavinsWallet();
    }, [filters]);
   

    return (
        <div>
            <div>
                <BreadCrumbTitle
                    title="Savings Wallet"
                    dataCount={list?.totalElements}
                />
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <SearchInput val={search} changeVal={setSearch} />
                    </div>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Wallet Number</th>
                                        <th>Wallet type</th>
                                        <th>ledger balance</th>
                                        <th>Available balance</th>
                                        <th>created at</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                {!load && list?.length > 0 && (
                                    <tbody>
                                        {list.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                    {limit * (page - 1) + i + 1}
                                                    </td>
                                                    <td>
                                                        { tr.wallet_number}
                                                    </td>
                                                    <td>
                                                        {tr.wallet_type}
                                                    </td>
                                                    <td>
                                                        {tr.ledger_balance}
                                                    </td>
                                                    <td>{tr.available_balance}</td>
                                                    <td>{dateFormat(tr.created_at)}</td>
                                                    <td>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                        />
                                                    </div>
                                                </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.totalPages > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.totalElements}
                                pageSize={limit}
                                lastPage={list.totalPages}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SavingsWallet;
