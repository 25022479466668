import React, { useEffect, useState } from 'react'
import CalculatedInterestFIlter from '../../../component/Interest/CalculatedInterestFIlter';
import ModalComponent from '../../../component/ModalComponent';
import LoadTable from '../../../component/Loaders/LoadTable';
import Paginate from '../../../component/Paginate';
import dateFormat from 'dateformat';
import { MdFilterListAlt } from 'react-icons/md';
import { FaFileCsv } from 'react-icons/fa6';
import { IoRefreshCircle } from 'react-icons/io5';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import { displayError } from '../../../utils/errors';
import InterestService from '../../../redux/features/interests/interestService';
import { useAppSelector } from '../../../redux/hooks';

function Earnings() {
  const {token} = useAppSelector(state => state.auth);

  const [openFilter, setOpenFilter] = useState(false);
  const [load, setLoad] = useState(false);
  const [list, setList] = useState<any>({});

  //FILTERS
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [startDate, setStartDate] = useState(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const [account, setAccount] = useState('');
  const [endDate, setEndDate] = useState(
      new Date(new Date().setDate(new Date().getDate() + 1)),
  );
  const [applyFilter, setApplyFilter] = useState(false);

  let filters = `?page=${page}&per_page=${limit} `;

  const fetchCalculatedInterest = async () => {
      try {
          setLoad(true);
          let res = await InterestService.getEarnings(
              token,
              filters,
          );
          setLoad(false);
          setList(res);
      } catch (err) {
          setLoad(false);
          setList([]);
          displayError(err, true);
      }
  };

  useEffect(() => {
      window.scrollTo(0, 0);
      fetchCalculatedInterest();
  }, [page, limit, applyFilter]);

  const resetTransaction = () => {
      setPage(1);
      setApplyFilter(false);
      setStartDate(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      );
      setEndDate(new Date(new Date().setDate(new Date().getDate() + 1)));
      setAccount('');
  };

  return (
      <div>
          <BreadCrumbTitle
              title="Earnings"
              dataCount={list?.meta?.total}
          />
          <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                  <div className="filter">
                      <button
                          className="main-btn"
                          onClick={() => resetTransaction()}>
                          <IoRefreshCircle />
                          <span>Reset</span>
                      </button>
                      {/* <button className="main-btn secondary">
                          <FaFileCsv />
                          <span>Export CSV</span>
                      </button> */}
                      <button
                          className="main-btn info"
                          onClick={() => setOpenFilter(true)}>
                          <MdFilterListAlt />
                          <span>Filter</span>
                      </button>
                  </div>
              </div>
          </div>

          <div className="basic-card mt-3">
              <div className="head"></div>
              <div className="body">
                  <div className="table-body">
                      <div className="table-responsive">
                          <table className="table">
                              <thead>
                                  <tr>
                                      <th>S/N</th>
                                      <th>User</th>
                                      <th>Account Number</th>
                                      <th>interest accured(ngn)</th>
                                      <th>last payout (ngn)</th>
                                      <th>last payout date</th> 
                                      <th>date</th>
                                  </tr>
                              </thead>
                              {!load && list?.data?.length > 0 && (
                                  <tbody>
                                      {list?.data?.map(
                                          (tr: any, i: number) => (
                                              <tr key={tr.id}>
                                                  <td>
                                                      {limit * (page - 1) +
                                                          i +
                                                          1}
                                                  </td>
                                                  <td>{tr.user_id}</td>
                                                  <td>{tr.account_number}</td>
                                                  
                                                  <td>
                                                      {tr.accrued_interest}
                                                  </td>

                                                  <td>{tr.last_payout_amount}</td>

                                                  <td>{tr.last_payout_date || '---'}</td>
                                                  <td>
                                                      {dateFormat(
                                                          tr.createdAt,
                                                          'mmm dd, yyyy | h:MM TT',
                                                      )}
                                                  </td>
                                              </tr>
                                          ),
                                      )}
                                  </tbody>
                              )}
                          </table>
                      </div>
                      {!load && list?.meta?.last_page > 1 && (
                          <Paginate
                              currentPage={page}
                              totalCount={list?.meta?.total}
                              pageSize={limit}
                              lastPage={list?.meta?.last_page}
                              onSelect={(p: number) => setPage(Number(p))}
                              onNext={(p: number) => setPage(p)}
                              onPrev={(p: number) => setPage(p)}
                              changeLimit={(p: string) => setLimit(Number(p))}
                          />
                      )}
                      {load && <LoadTable />}
                  </div>
              </div>
          </div>
          <ModalComponent
              open={openFilter}
              close={() => setOpenFilter(false)}
              title="Filter Earnings"
            >
              <CalculatedInterestFIlter
                  account={account}
                  setAccount={setAccount}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  filter={() => {
                      setApplyFilter(!applyFilter);
                      setOpenFilter(false);
                  }}
              />
          </ModalComponent>
      </div>
  )
}

export default Earnings