import {Spinner} from 'react-bootstrap';

const BasicLoad = () => {
    return (
        <div className="text-center">
            <Spinner animation="border" variant="primary" />
        </div>
    );
};

export default BasicLoad;
