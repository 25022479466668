import React, {useEffect, useState} from 'react';
import {SelectInput, TextInput} from '../BasicInputs';
import {displayError} from '../../utils/errors';
import extraService from '../../redux/features/extras/extraService';
import {useAppSelector} from '../../redux/hooks';

interface props {
    name: any;
    setName: (arg: any) => void;
    description: any;
    setDescription: (arg: any) => void;
    transactionCategory: any;
    setTransactionCategory: (arg: any) => void;
    transactionServiceCode: any;
    setTransactionServiceCode: (arg: any) => void;
    limitType: any;
    setLimitType: (arg: any) => void;
    // limit: any;
    // setLimit: (arg: any) => void;
    type: any;
    setType: (arg: any) => void;
    userCategory: any;
    setUserCategory: (arg: any) => void;
    transactionCategoryList: any;
    serviceCodeDetails: any;
    limitDetails: any;
    data: any;
    closed: () => void;
    limitValue: any;
    setLimitValue: (arg: any) => void;
    kyc: any;
    setKyc: (arg: any) => void;
}

function EditTransactionLimitConfig({
    name,
    type,
    setName,
    setType,
    limitType,
    description,
    setLimitType,
    userCategory,
    setDescription,
    setUserCategory,
    serviceCodeDetails,
    transactionCategory,
    setTransactionCategory,
    transactionServiceCode,
    transactionCategoryList,
    setTransactionServiceCode,
    limitDetails,
    data,
    closed,
    limitValue,
    setLimitValue,
    kyc,
    setKyc,
}: props) {
    const {token, details} = useAppSelector(state => state.auth);

    const [load, setLoad] = useState(false);

    useEffect(() => {
        if (data?._id) {
            setName(data.name);
            setKyc(data?.kycLevel);
            setLimitValue(data?.limit);
            setLimitType(data?.limitType);
            setDescription(data?.description);
            setTransactionCategory(data?.transactionCategoryCode);
            setTransactionServiceCode(data?.transactionServiceCode);
        }
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const newData = {
            name,
            description,
            transactionServiceCode,
            transactionCategoryCode: transactionCategory.split(',')[0],
            kycLevel: kyc,
            limitType,
            limit: limitValue,
        };

        try {
            setLoad(true);

            await extraService.updateTransactionConfig(
                token,
                newData,
                data?._id,
                {aes_key: details.aes_key, aes_iv: details.aes_iv},
            );
            setLoad(false);
            closed();
            reset()
        } catch (err) {
            displayError(err, true);
        }
    };

    const reset =()=>{
        setName('');
        setKyc('');
        setLimitValue('');
        setLimitType('');
        setDescription('');
        setTransactionCategory('');
        setTransactionServiceCode('');
    }

    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={name}
                    changeVal={setName}
                    label="Limit Configuration Name"
                    type="text"
                    placeholder="limit configuration Name"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={description}
                    changeVal={setDescription}
                    label="Limit Description"
                    type="text"
                    placeholder="Description"
                    required
                />
            </div>

            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={transactionCategoryList?.transactionCategory?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.categoryCode},${i._id}`,
                        }),
                    )}
                    val={transactionCategory}
                    changeVal={setTransactionCategory}
                    label="Transaction Category"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <SelectInput
                    option={serviceCodeDetails?.service?.map((i: any) => ({
                        label: i.serviceName,
                        value: `${i.serviceCode}`,
                    }))}
                    val={transactionServiceCode}
                    changeVal={setTransactionServiceCode}
                    label="Transaction Service Code"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-4 mb-3">
                <SelectInput
                    option={limitDetails?.transactionLimitType?.map(
                        (i: any) => ({
                            label: i.name,
                            value: `${i.tnxLimitTypeCode}`,
                        }),
                    )}
                    val={limitType}
                    changeVal={setLimitType}
                    label="Limit Type"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-4 mb-3">
                <TextInput
                    val={limitValue}
                    changeVal={setLimitValue}
                    label="Limit Value"
                    type="text"
                    placeholder="Limit Value"
                    required
                />
            </div>

            <div className="col-lg-4 mb-3">
                <TextInput
                    val={kyc}
                    changeVal={setKyc}
                    label="Kyc Level"
                    type="text"
                    placeholder="Kyc Level"
                    required
                />
            </div>

            <div className="col-lg-12 text-center">
                <button onClick={handleSubmit} className="main-btn">
                    {load ? 'loading' : `${data?._id ? 'Edit' : 'Create'}`}
                </button>
            </div>
        </div>
    );
}

export default EditTransactionLimitConfig;
