import {JSEncrypt} from 'jsencrypt';
import CryptoJS from 'crypto-js';

let defaultKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAp71hVFgCfpYu1P4yFV6C
oFh3zF37h4fQtLo1+IObP1zAZBc8LPIEerXa0TvrSoR0sR1yhzPWTx1OEaZVxzY2
uDGNN+yhhr3vKmQn3yMJZB0bjf4iGC36vCjMwdRuk8DayOWRb++XtxdgHAaAvYqG
jAPV/BQ7PHd4mXoVBLvtzi1CkQgpAhyyi2VBKRm7LoW9jFIEOKkubiKGfG6M2Rbm
MLY50ZyCD5fVgYX6X8ELE5HwVRvpUtEKuS20Mvnpzcdy6JzU/py1BRt8OF+dHjTO
9oNeQnaLThyUTvI/gp9MV8qtm34gWHv17kmcbcvpSkaRA6RuhGdBwGtU1qwje9kq
ezWYyH4xNeokBD0+CquL6BEDAVpzAa1jFXz1Ebjo651NuSPCMO2XyvjwvIgoTrjS
4Bu/aqINki9BzyvNfP20QVKbjKiZ1MPjfAbnmMKKJYQCDX7lbDYtege+20u8uJfo
pFrKErL1VZbyPQJGw7FtqrU6BVH3uHp6yc8ynhV91oduWKZskPmx9kg2OXjP7JzJ
VRNFZCywtdUnZ2OACXG1TAs5MyrHlb5WNLhxlGU3rGUcIV7nJSaFGXDZCbz+nDtF
l4mkl6myl2oZtF4DKnhqvZ3f1/T0ZdS1q9sobwpOsGlQf3f6v2fGp9g1icWYR2v6
oAR91aWy5tdN2K8rihukaL8CAwEAAQ==
-----END PUBLIC KEY-----`;

const getEncrypt = (key: any, payload: any) => {
    let encrypted = new JSEncrypt();
    encrypted.setPublicKey(key);
    return encrypted.encrypt(JSON.stringify(payload));
};

const getNewEncrypt = (key: any, data: any) => {
    let hashedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Base64.parse(key.aes_key),
        {
            iv: CryptoJS.enc.Base64.parse(key.aes_iv),
            mode: CryptoJS.mode.CBC,
        },
    ).toString();

    let base64 = CryptoJS.enc.Base64.parse(hashedData);
    let hex = CryptoJS.enc.Hex.stringify(base64);
    return hex;
};

const getPayload = (key: any, payload: any, version: string) => {
    if (version === 'v3') {
        let encrypted = getEncrypt(key || defaultKey, payload);
        return {encrypted};
    } else if (version === 'v3a') {
        let encrypted = getNewEncrypt(key, payload);
        return {encrypted};
    } else {
        return payload;
    }
};

const encryptToken = (data: any, key: any, iv: any) => {
    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(key), {
        iv: CryptoJS.enc.Hex.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });
    return encrypted.toString();
};

const decryptToken = (ciphertext: any, key: any, iv: any) => {
    const decrypted = CryptoJS.AES.decrypt(
        ciphertext,
        CryptoJS.enc.Hex.parse(key),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        },
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
};

export {getEncrypt, getNewEncrypt, getPayload, encryptToken, decryptToken};
