import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {ToastContainer} from 'react-toastify';
import Routing from './Routing';
import {persistStore} from 'redux-persist';

import 'react-toastify/dist/ReactToastify.css';
import store from './redux/store';
import {useEffect} from 'react';
import {generateAppCheckToken} from './utils/firebase';

let persistor = persistStore(store);

const App = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            generateAppCheckToken();
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Routing />
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </PersistGate>
        </Provider>
    );
};

export default App;
