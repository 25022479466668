import { toast } from "react-toastify";

const displayError = (error: any, display?: boolean) => {
	const message =
		error?.response?.data?.message ||
		error?.response?.data?.response_message ||
		error?.message?.toString() ||
		error.toString();

	if (display) {
		toast.error(Array.isArray(message) ? message[0] : message);
	}
	return message;
};

export { displayError };
