 
import {authHeader} from '../../../utils/headers';
import {getPayload} from '../../../utils/encrypt';
import {apiRequest} from '../../../utils/axiosInstance';

const fetchBeneficiaries = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/transaction/get_beneficiaries`,
        {
            headers: authHeader(token),
        },
    );
    return data;
};
const addBeneficiaries = async (token: string, payload: any, key: any) => {
    let passPayload = getPayload(key, payload, 'v3a');
    const {data} = await apiRequest('baseUrl').post(
        `/transaction/add_beneficiary`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const updateBeneficiaries = async (token: string, id: string,key:any) => {
    let passPayload = getPayload(key, {}, 'v3a');
    const {data} = await apiRequest('baseUrl').patch(
        `/transaction/toggle_beneficiary/${id}`,
        passPayload,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const deleteBeneficiaries = async (token: string, id: string) => {
    const {data} = await apiRequest('baseUrl').delete(
        `/transaction/delete_beneficiary/${id}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const beneficiariesService = {
    fetchBeneficiaries,
    updateBeneficiaries,
    deleteBeneficiaries,
    addBeneficiaries,
};

export default beneficiariesService;
