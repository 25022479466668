import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import serviceService from '../../../../redux/features/service/serviceService';
import LoadTable from '../../../../component/Loaders/LoadTable';
import Paginate from '../../../../component/Paginate';
import {MdFilterListAlt} from 'react-icons/md';
import {FaFileCsv} from 'react-icons/fa6';
import {IoRefreshCircle} from 'react-icons/io5';
import {formatCurrency} from '../../../../utils/numberFormat';
import dateFormat from 'dateformat';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {Link} from 'react-router-dom';

function BillTransaction() {
    const {token} = useAppSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});

    // Filters
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);

    let filters = `?page=${page}&per_page=${limit}`;

    // let transactionFilters = `?from=${startDate}&to=${endDate}&transaction_ref=${transactionRef}&amount=${amount}&source_account_no=${sourceAccountNumber}&dest_account_no=${destinationAccountNumber}&status=${status}&source_account_name=${sourceAccountName}`;

    const fetchTransaction = async () => {
        try {
            setLoad(true);
            let res = await serviceService.getBillTransactions(token, filters);
            setLoad(false);
            if (res) {
                setList(res);
            }
        } catch (err) {
            setLoad(false);
            setList([]);
        }
    };

    const reset=()=>{
        setPage(1);
        setLimit(25)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchTransaction();
    }, [page, limit]);

    const checker = (status: any) => {
        let txt, class_status;
        if (status === 'PENDING') {
            txt = 're-query';
            class_status = 'bg bg-orange';
        }
        return {txt, class_status};
    };

    return (
        <div>
            <BreadCrumbTitle
                title="Bills Transactions"
                dataCount={list?.total}
            />
            <div className="row mt-3">
                <div className="col-lg-6 d-flex"></div>
                <div className="col-lg-6">
                    <div className="filter">
                        <button className="main-btn" onClick={() => {reset()}}>
                            <IoRefreshCircle />
                            <span>Reset</span>
                        </button>
                        {/* <button className="main-btn secondary">
                            <FaFileCsv />
                            <span>Export CSV</span>
                        </button> */}
                        <button className="main-btn info" onClick={() => {}}>
                            <MdFilterListAlt />
                            <span>Filter</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>User Id</th>
                                        <th>Customer Id</th>
                                        <th>Amount</th>
                                        <th>Institution Name</th>
                                        <th>Biller Request Reference</th>
                                        <th>Date</th>
                                        <th>Biller Transfer Reference</th>
                                        <th>Biller Response Code</th>
                                        <th>Biller Response Message</th>
                                        <th>Biller Returned Balance</th>
                                        <th>Biller Returned Pin</th>
                                        <th>Biller Response Group</th>
                                        <th>Re-query/Reverse</th>
                                    </tr>
                                </thead>
                                {!load && list?.transactions?.length > 0 && (
                                    <tbody>
                                        {list.transactions.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {limit * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>
                                                    <td className="link">
                                                        <Link
                                                            to={`/dashboard/customers/bills`}
                                                            state={tr.userId}>
                                                            {tr.userId}
                                                        </Link>
                                                    </td>
                                                    <td>{tr.customerId}</td>
                                                    <td>
                                                        {formatCurrency(
                                                            tr.amount,
                                                        )}
                                                    </td>
                                                    <td>
                                                        {tr.institutionName}
                                                    </td>
                                                    <td>
                                                        {
                                                            tr.biller_requestReference
                                                        }
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            tr.timeStamp,
                                                        )}
                                                    </td>
                                                    <td>
                                                        {tr.Biller_trans_ref
                                                            ? tr.Biller_trans_ref
                                                            : 'not provided'}
                                                    </td>
                                                    <td>
                                                        {tr.Biller_resp_code}
                                                    </td>
                                                    <td>
                                                        {tr.Biller_resp_msg}
                                                    </td>
                                                    <td>
                                                        {tr.Biller_return_bal
                                                            ? tr.Biller_return_bal
                                                            : 'not given'}
                                                    </td>
                                                    <td>
                                                        {tr.Biller_return_pin
                                                            ? tr.Biller_pin
                                                            : 'not given'}
                                                    </td>
                                                    <td>
                                                        <span
                                                            className={`badge ${
                                                                tr.Biller_resp_group ===
                                                                'SUCCESSFUL'
                                                                    ? 'badge-success'
                                                                    : 'badge-warning'
                                                            }`}>
                                                            {
                                                                tr.Biller_resp_group
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {tr.Biller_resp_group && (
                                                            <button
                                                                type="button"
                                                                className={
                                                                    checker(
                                                                        tr.Biller_resp_group,
                                                                    )
                                                                        .class_status
                                                                }
                                                                style={{
                                                                    padding:
                                                                        '.2em .5em',
                                                                    color: '#fff',
                                                                    borderRadius:
                                                                        '5px',
                                                                }}>
                                                                <span>
                                                                    {
                                                                        checker(
                                                                            tr.Biller_resp_group,
                                                                        ).txt
                                                                    }
                                                                </span>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.nextPage > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list.allTransactions}
                                pageSize={limit}
                                lastPage={list?.nextPage}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) => setLimit(Number(p))}
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillTransaction;
