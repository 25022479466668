import React from 'react';
import {SelectInput, TextInput} from '../BasicInputs';

interface props {
    amountType: string;
    setAmountType: (arg: string) => void;
    capAmount: string;
    setCapAmount: (arg: string) => void;
    capBase: string;
    setCapBase: (arg: string) => void;
    amount: string;
    setAmount: (arg: string) => void;
}

function EditSavingsPenality({
    amountType,
    setAmountType,
    capAmount,
    setCapAmount,
    capBase,
    setCapBase,
    amount,
    setAmount,
}: props) {
    return (
        <div className="row">
            <div className="col mb-3">
                <div className="col-lg-12 mb-3">
                    <TextInput
                        val={amount}
                        changeVal={setAmount}
                        label="Amount"
                        type="text"
                        placeholder=""
                        disabled={true}
                    />
                </div>
                <SelectInput
                    option={[
                        {label: 'Fixed', value: 'fixed'},
                        {label: 'Variable', value: 'variable'},
                    ]}
                    val={amountType}
                    changeVal={setAmountType}
                    label="Operating system of the Machine"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={capAmount}
                    changeVal={setCapAmount}
                    label="Cap Amount"
                    type="text"
                    placeholder="Cap amount"
                    required
                />
            </div>
            <div className="col mb-3">
                <SelectInput
                    option={[
                        {label: 'Interest', value: 'interst'},
                        {label: 'Capital', value: 'capital'},
                    ]}
                    val={capBase}
                    changeVal={setCapBase}
                    label="Operating system of the Machine"
                    placeholder="Select"
                    required
                />
            </div>
            <div className="col-lg-12 text-center">
                <button className="main-btn">Create</button>
            </div>
        </div>
    );
}

export default EditSavingsPenality;
