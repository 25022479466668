import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../redux/hooks';
import extraService from '../../../../redux/features/extras/extraService';
import {displayError} from '../../../../utils/errors';
import ModalComponent from '../../../../component/ModalComponent';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import {AiOutlineDelete} from 'react-icons/ai';
import TransactionLimitConfig from '../../../../component/Extras/TransactionLimitConfig';
import Paginate from '../../../../component/Paginate';
import LoadTable from '../../../../component/Loaders/LoadTable';
import {GoPencil} from 'react-icons/go';
import EditTransactionLimitConfig from '../../../../component/Extras/EditTransactionLimitConfig';

function TransactionsConfig() {
    const {token} = useAppSelector(state => state.auth);

    //ADD/EDIT CONFIG

    const [name, SetName] = useState('');
    const [description, setDescription] = useState('');
    const [transactionCategory, setTransactionCategory] = useState('');
    const [transactionServiceCode, setTransactionServiceCode] = useState('');
    const [limitType, setLimitType] = useState('');
    const [limit, setLimit] = useState('');
    const [type, setType] = useState('');
    const [userCategory, setUserCategory] = useState('');
    const [details, setDetails] = useState<any>({});
    const [transactionCategoryList, setTransactionCategoryList] = useState<any>([]);
    const [serviceCodeDetails, setServiceCodeDetails] = useState<any>([]);
    const [limitDetails, setLimitDetails] = useState<any>([]);
    const [kyc,setKyc] = useState('')

    //filters
    const [page, setPage] = useState(1);
    const [limits, setLimits] = useState(15);
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [add, setAdd] = useState(false);
    const [edit,setEdit] = useState(false)

    const filters = `?page=${page}`;

    const fetchTransactionConfig = async () => {
        try {
            setLoad(true);
            const res = await extraService.getTransactionConfig(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };

    const fetchTransCategory = async () => {
        try {
            setLoad(true);
            const res = await extraService.getTransactionCategories(token);
            setLoad(false);
            setTransactionCategoryList(res?.data);
        } catch (err) {
            setLoad(false);
            setTransactionCategoryList([]);
            displayError(err, true);
        }
    };
    const fetchLimit = async () => {
        try {
            setLoad(true);
            const res = await extraService.getTransactionLimit(token);
            setLoad(false);
            setLimitDetails(res?.data);
        } catch (err) {
            setLoad(false);
            setLimitDetails([]);
            displayError(err, true);
        }
    };

    const fetchServiceCode = async () => {
        try {
            setLoad(true);
            const res = await extraService.getServiceCode(
                token,
                transactionCategory.split(',')[1],
            );
            setLoad(false);
            setServiceCodeDetails(res?.data);
        } catch (err) {
            setLoad(false);
            setServiceCodeDetails([]);
            displayError(err, true);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchTransactionConfig();
        fetchTransCategory()
        fetchLimit()
        if (transactionCategory) {
            fetchServiceCode();
        }
    }, [filters,transactionCategory]);

    
    return (
        <div>
            <BreadCrumbTitle
                title="Transactions Limit Config"
                dataCount={list?.allTxnLimitConfigs}
                button="+ Add"
                buttonClick={() => setAdd(true)}
            />

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Kyc level</th>
                                        <th>User category</th>
                                        <th>limit type</th>
                                        <th>limit value</th>
                                        <th>service name</th>
                                        <th>catgeory name</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                {!load && list?.txnLimitConfigs?.length > 0 && (
                                    <tbody>
                                        {list.txnLimitConfigs.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {' '}
                                                        {limits * (page - 1) +
                                                            i +
                                                            1}
                                                    </td>

                                                    <td>{tr.name}</td>
                                                    <td>{tr.description}</td>
                                                    <td>{tr.kycLevel}</td>
                                                    <td>
                                                        {tr.userCategory ||
                                                            '--'}
                                                    </td>
                                                    <td>{tr.limitType}</td>
                                                    <td>{tr.limit}</td>
                                                    <td>
                                                        {
                                                            tr
                                                                .transactionServiceId
                                                                ?.serviceName
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            tr
                                                                .transactionCategoryId
                                                                ?.name
                                                        }
                                                    </td>

                                                    <td>
                                                        <span
                                                            onClick={() => {
                                                                setEdit(true);
                                                                setDetails(tr);
                                                            }}>
                                                            <GoPencil />
                                                        </span>
                                                        <span>
                                                            <AiOutlineDelete />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {!load && list?.lastPage > 1 && (
                            <Paginate
                                currentPage={page}
                                totalCount={list?.allTxnLimitConfigs}
                                pageSize={limits}
                                lastPage={list?.lastPage}
                                onSelect={(p: number) => setPage(Number(p))}
                                onNext={(p: number) => setPage(p)}
                                onPrev={(p: number) => setPage(p)}
                                changeLimit={(p: string) =>
                                    setLimits(Number(p))
                                }
                            />
                        )}
                        {load && <LoadTable />}
                    </div>
                </div>
            </div>
            <ModalComponent
                open={add}
                size="lg"
                title="Transaction Limit Config"
                close={() => setAdd(false)}>
                <TransactionLimitConfig
                    name={name}
                    setName={SetName}
                    description={description}
                    setDescription={setDescription}
                    transactionCategory={transactionCategory}
                    setTransactionCategory={setTransactionCategory}
                    transactionServiceCode={transactionServiceCode}
                    setTransactionServiceCode={setTransactionServiceCode}
                    limitType={limitType}
                    setLimitType={setLimitType}
                    userCategory={userCategory}
                    setUserCategory={setUserCategory}
                    type={type}
                    setType={setType}
                    limitValue={limit}
                    setLimitValue={setLimit}
              
                    closed={() => {
                        setAdd(false);
                        fetchTransactionConfig();
                    }}
                    transactionCategoryList={transactionCategoryList}
                    serviceCodeDetails={serviceCodeDetails}
                    limitDetails={limitDetails}
                />
            </ModalComponent>

            <ModalComponent
                open={edit}
                size="lg"
                title="Transaction Limit Config"
                close={() => setEdit(false)}>
                <EditTransactionLimitConfig
                    name={name}
                    setName={SetName}
                    description={description}
                    setDescription={setDescription}
                    transactionCategory={transactionCategory}
                    setTransactionCategory={setTransactionCategory}
                    transactionServiceCode={transactionServiceCode}
                    setTransactionServiceCode={setTransactionServiceCode}
                    limitType={limitType}
                    setLimitType={setLimitType}
                    userCategory={userCategory}
                    setUserCategory={setUserCategory}
                    type={type}
                    setType={setType}
                    limitValue={limit}
                    setLimitValue={setLimit}
                    data={details}
                    closed={() => {
                        setEdit(false);
                        fetchTransactionConfig();
                    }}
                    transactionCategoryList={transactionCategoryList}
                    serviceCodeDetails={serviceCodeDetails}
                    limitDetails={limitDetails}
                    kyc={kyc}
                    setKyc={setKyc}
                />
            </ModalComponent>
        </div>
    );
}

export default TransactionsConfig;
