import React, {useEffect, useState} from 'react';
import {SelectInput, TextInput} from '../../../component/BasicInputs';
import axios from 'axios';
import {displayError} from '../../../utils/errors';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {authHeader, headers} from '../../../utils/headers';
import {useAppSelector} from '../../../redux/hooks';
import config from '../../../utils/config';
import BasicLoad from '../../../component/Loaders/BasicLoad';
import agentService from '../../../redux/features/agent/agentService';

function New() {
    const {id} = useParams();

    const details = useLocation().state;

    const type = id === 'new' ? 'create' : 'edit';

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [phone, setPhone] = useState('');
    const [businessAddress, setBusinessAddress] = useState('');
    const [userId, setUserId] = useState('');
    const [superAgent, setSuperAgent] = useState('');
    const [agentCat, setAgentCat] = useState('');
    const [imageLoader, setImageLoader] = useState(false);
    const [cac, setCac] = useState('');
    const [isSuperAgent, setIsSuperAgent] = useState<boolean>(false);
    const [addressProofUrl, setAddressProofUrl] = useState('');
    const [identityDocumentUrl, setIdentityDocumentUrl] = useState('');
    const [load, setLoad] = useState(false);
    const [catDet, setCatDet] = useState<any>([]);

    const {token} = useAppSelector(state => state.auth);

    const navigate = useNavigate();

    useEffect(() => {
        if (details?.id && type === 'edit') {
            setFirstName(details.firstName);
            setLastName(details.lastName);
            setEmail(details.email);
            setBusinessAddress(details.businessAddress);
            setBusinessName(details.businessName);
            setPhone(details.phone);
            setUserId(details.userId);
            setIsSuperAgent(details.isSuperAgent);
            setIdentityDocumentUrl(details.identityDocument);
            setCac(details.cac);
            setAddressProofUrl(details.addressProof);
            setAgentCat(details.agentCategoryId);
            setSuperAgent(details.superAgentId);
        }
    }, [details]);

    const checkHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: string,
    ) => {
        if (e.target.checked && type === 'No') {
            console.log('Test1');
            setIsSuperAgent(false);
        } else if (e.target.checked && type === 'Yes') {
            console.log('Test 2');
            setIsSuperAgent(true);
        }
    };

    const handleFileUpload = async (
        e: React.ChangeEvent<HTMLInputElement>,
        type: string,
    ) => {
        try {
            setImageLoader(true);
            const file = e.target.files?.[0];
            if (!file) return;

            const formData = new FormData();
            formData.append('file', file);

            const {data} = await axios.post(
                `https://api.getripay.com/files/api/documents`,
                formData,
            );
            const res = data?.data?.document_url;
            switch (type) {
                case 'cac':
                    setCac(res);
                    break;
                case 'addressProof':
                    setAddressProofUrl(res);
                    break;
                case 'identityDocument':
                    setIdentityDocumentUrl(res);
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
            alert('Unable to connect to upload document');
        } finally {
            setImageLoader(false);
        }
    };

    const handleAgentSubmit = async (e: any) => {
        e.preventDefault();
        const payload = {
            firstName,
            lastName,
            email,
            businessAddress,
            businessName,
            phone,
            userId,
            isSuperAgent,
            identityDocument: identityDocumentUrl,
            cac,
            addressProof: addressProofUrl,
            agentCategoryId: agentCat,
            superAgentId: superAgent,
        };
        try {
            setLoad(true);
            let response;
            if (id !== 'new') {
                response = await axios.patch(
                    `${config.posUrl}/agent/${id}`,
                    payload,
                    {
                        headers: authHeader(token),
                    },
                );
            } else {
                response = await axios.post(`${config.posUrl}/agent`, payload, {
                    headers: authHeader(token),
                });
            }

            const data = response.data;
            if (data.success) {
                navigate('/dashboard/agents/all');
            }
        } catch (err: any) {
            displayError(err, true);
        } finally {
            setLoad(false);
        }
    };

    const getAgentCat = async () => {
        try {
            const res = await agentService.AgentCategories(token);
            setCatDet(res);
        } catch (err) {
            displayError(err, true);
        }
    };

    useEffect(() => {
        getAgentCat();
    }, []);

    return (
        <>
            <h5 className="title">
                {type === 'create' ? 'Create' : 'Edit'} Agent
            </h5>
            <div className="row">
                <div className="col-lg-12">
                    <div className="basic-card mt-3">
                        <div className="head"></div>
                        <div className="body">
                            <div className="form-body">
                                <form
                                    onSubmit={handleAgentSubmit}
                                    className="row agent-box">
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={firstName}
                                            changeVal={setFirstName}
                                            label="First Name"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={lastName}
                                            changeVal={setLastName}
                                            label="last Name"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={email}
                                            changeVal={setEmail}
                                            label="Email"
                                            type="email"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={userId}
                                            changeVal={setUserId}
                                            label="User Id (Provide this if agent is a Getripay User)"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={businessAddress}
                                            changeVal={setBusinessAddress}
                                            label="Business Address"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={businessName}
                                            changeVal={setBusinessName}
                                            label="Business Name"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <TextInput
                                            val={phone}
                                            changeVal={setPhone}
                                            label="Phone Number"
                                            required
                                        />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <SelectInput
                                            option={[
                                            ]}
                                            val={superAgent}
                                            placeholder='select'
                                            changeVal={setSuperAgent}
                                            label="Select super Agent"
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <SelectInput
                                            option={catDet?.map((i: any) => ({
                                                label: i.name,
                                                value: i.id,
                                            }))}
                                            val={agentCat}
                                            placeholder='select'
                                            changeVal={setAgentCat}
                                            label="Agent Category"
                                        />
                                    </div>
                                    <div className="mb-2 mt-4 col-lg-6">
                                        <label>Is Agent a Super Agent?</label>
                                        <div className="d-flex col-lg-11 gap-3">
                                            <div className="d-flex align-items-center mr-3">
                                                <input
                                                    type="checkbox"
                                                    checked={isSuperAgent}
                                                    onChange={e =>
                                                        checkHandler(e, 'Yes')
                                                    }
                                                />
                                                <span className="ml-2">
                                                    Yes
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={!isSuperAgent}
                                                    onChange={e =>
                                                        checkHandler(e, 'No')
                                                    }
                                                />
                                                <span className="ml-2">No</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                                        <label>
                                            {type === 'edit'
                                                ? 'Change'
                                                : 'Upload'}{' '}
                                            CAC Document
                                        </label>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn wide">
                                                Click to Upload
                                            </button>
                                            <input
                                                type="file"
                                                name="myfile"
                                                onChange={e =>
                                                    handleFileUpload(e, 'cac')
                                                }
                                                disabled={imageLoader}
                                            />
                                        </div>
                                        {imageLoader && <p>Loading...</p>}
                                        {cac && (
                                            <a
                                                href={cac}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{fontSize: '0.7rem'}}>
                                                View Uploaded file
                                            </a>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                                        <label>
                                            {type === 'edit'
                                                ? 'Change'
                                                : 'Upload'}{' '}
                                            Proof of Address
                                        </label>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn wide">
                                                Click to Upload
                                            </button>
                                            <input
                                                type="file"
                                                name="myfile"
                                                onChange={e =>
                                                    handleFileUpload(
                                                        e,
                                                        'addressProof',
                                                    )
                                                }
                                                disabled={imageLoader}
                                            />
                                        </div>
                                        {imageLoader && <p>Loading...</p>}
                                        {addressProofUrl && (
                                            <a
                                                href={addressProofUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{fontSize: '0.7rem'}}>
                                                View Uploaded file
                                            </a>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column mt-3 col-lg-6 mb-3">
                                        <label>
                                            {type === 'edit'
                                                ? 'Change'
                                                : 'Upload'}{' '}
                                            Valid ID Card
                                        </label>
                                        <div className="upload-btn-wrapper">
                                            <button className="btn wide">
                                                Click to Upload
                                            </button>
                                            <input
                                                type="file"
                                                name="myfile"
                                                onChange={e =>
                                                    handleFileUpload(
                                                        e,
                                                        'identityDocument',
                                                    )
                                                }
                                                disabled={imageLoader}
                                            />
                                        </div>
                                        {imageLoader && <p>Loading...</p>}
                                        {identityDocumentUrl && (
                                            <a
                                                href={identityDocumentUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{fontSize: '0.7rem'}}>
                                                View Uploaded file
                                            </a>
                                        )}
                                    </div>
                                    <div className="col-lg-6"></div>
                                    <div className="col-lg-12 text-center">
                                        {load ? (
                                            <BasicLoad />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="main-btn">
                                                {' '}
                                                {type === 'edit'
                                                    ? 'Update'
                                                    : 'Create'}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default New;
