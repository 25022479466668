import {DateSelect, TextInput} from '../BasicInputs';

interface Props {
    startDate: any;
    setStartDate: (arg: any) => void;
    endDate: any;
    setEndDate: (arg: any) => void;
    transactionRef: string;
    setTransactionRef: (arg: string) => void;
    amount: string;
    setAmount: (arg: string) => void;
    debitedAccount: string;
    setDebitedAccount: (arg: string) => void;
    narration: string;
    setNarration: (arg: string) => void;
    filter: () => void;
}

const Filters = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    transactionRef,
    setTransactionRef,
    amount,
    setAmount,
    debitedAccount,
    setDebitedAccount,
    narration,
    setNarration,
    filter,
}: Props) => {
    return (
        <div className="row">
            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={startDate}
                    changeDateVal={setStartDate}
                    label="Start Date"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <DateSelect
                    dateVal={endDate}
                    changeDateVal={setEndDate}
                    label="End Date"
                    required
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={transactionRef}
                    changeVal={setTransactionRef}
                    label="Transaction Ref"
                    placeholder="Filter bt transaction reference"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={debitedAccount}
                    changeVal={setDebitedAccount}
                    label="Debited Account Number"
                    type="filter by debited Account Number"
                />
            </div>
            <div className="col-lg-6 mb-3">
                <TextInput
                    val={amount}
                    changeVal={setAmount}
                    label="Filter by Amount"
                />
            </div>

            <div className="col-lg-6 mb-3">
                <TextInput
                    val={narration}
                    changeVal={setNarration}
                    label="Enter Narration"
                />
            </div>

            <div className="col-lg-12 text-center">
                <button onClick={() => filter()} className="main-btn">
                    Filter Transactions
                </button>
            </div>
        </div>
    );
};

export default Filters;
