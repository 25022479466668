
import {authHeader} from '../../../utils/headers';
import {apiRequest} from '../../../utils/axiosInstance';

const getSavingsWallet = async (token: string, filters: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/user_wallets?${filters}`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const getUserSavings = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(`/savinvest/user_savings`, {
        headers: authHeader(token),
    });
    return data.data;
};
const getSavingsPenality = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/savings_penalties`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const getwalletTransactions = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/wallet_transactions`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};
const getwithdrawalDate = async (token: string) => {
    const {data} = await apiRequest('baseUrl').get(
        `/savinvest/withdrawal_dates`,
        {
            headers: authHeader(token),
        },
    );
    return data.data;
};

const savingsService = {
    getSavingsWallet,
    getUserSavings,
    getSavingsPenality,
    getwalletTransactions,
    getwithdrawalDate,
};
export default savingsService;
