import React, {useEffect, useState} from 'react';
import {AiOutlineDelete} from 'react-icons/ai';
import {useAppSelector} from '../../../../redux/hooks';
import beneficiariesService from '../../../../redux/features/beneficiaries/beneficiariesSlice';
import {displayError} from '../../../../utils/errors';
import feeService from '../../../../redux/features/fee/feeService';
import BreadCrumbTitle from '../../../../component/BreadCrumbTitle';
import ModalComponent from '../../../../component/ModalComponent';
import FeeForm from './FeeForm';
import {GoPencil} from 'react-icons/go';
import {MdShare} from 'react-icons/md';
import extraService from '../../../../redux/features/extras/extraService';

function Fees() {
    const {token} = useAppSelector(state => state.auth);

    //ADD?EDIT MODAL
    const [title, setTitle] = useState('');
    const [userType, setUserType] = useState('');
    const [kycLevel, setKycLevel] = useState('');
    const [transactionCategory, setTransactionCategory] = useState('');
    const [transactionServiceCode, setTransactionServiceCode] = useState('');
    const [feeType, setFeeType] = useState('');
    const [hasRange, setHasRange] = useState('');
    const [feeCap, setFeeCap] = useState('');
    const [extraFee, setExtraFee] = useState('');
    const [biller, setBiller] = useState('');
    const [description, setDescription] = useState('');
    const [feeValue, setFeeValue] = useState('');
    const [details, setDetails] = useState<any>({});

    const [transactionCategoryDetails, setTransactionCategoryDetails] = useState<any>([]);
    const [serviceCodeDetails, setServiceCodeDetails] = useState<any>([]);

    //filters
    const [load, setLoad] = useState(false);
    const [list, setList] = useState<any>({});
    const [add, setAdd] = useState(false);

    const filters = `?page`;

    const fetchFees = async () => {
        try {
            setLoad(true);
            const res = await feeService.getFees(token, filters);
            setLoad(false);
            setList(res);
        } catch (err) {
            setLoad(false);
            setList([]);
            displayError(err, true);
        }
    };
    const submit =()=>{
        
    }

    const fetchTransCategory = async () => {
        try {
            setLoad(true);
            const res = await extraService.getTransactionCategories(token);
            setLoad(false);
            setTransactionCategoryDetails(res?.data);
        } catch (err) {
            setLoad(false);
            setTransactionCategoryDetails([]);
            displayError(err, true);
        }
    };

    const fetchServiceCode = async () => {
        try {
            setLoad(true);
            const res = await extraService.getServiceCode(
                token,
                transactionCategory.split(',')[1],
            );
            setLoad(false);
            setServiceCodeDetails(res?.data);
        } catch (err) {
            setLoad(false);
            setServiceCodeDetails([]);
            displayError(err, true);
        }
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFees();
        fetchTransCategory();
        if (transactionCategory) {
            fetchServiceCode();
        }
    }, [transactionCategory]);

    const checkCategory = (
        type: string,
        categories: string,
        levels: string,
        userCategory: string,
        userLevel: string,
    ) => {
        const findCat =
            categories &&
            Array.isArray(categories) &&
            categories.find(c => c.id === userCategory);
        const findLevel =
            levels &&
            Array.isArray(levels) &&
            levels.find(c => c.id === userLevel);
        if (type === 'pos') {
            return findCat ? findCat.name : '';
        } else {
            return findLevel ? findLevel.name : '';
        }
    };
    return (
        <div>
            <BreadCrumbTitle
                title="Fee Profiles"
                dataCount={list?.feeProfiles?.length}
                button="+ New Fee"
                buttonClick={() => setAdd(true)}
            />

            <div className="basic-card mt-3">
                <div className="head"></div>
                <div className="body">
                    <div className="table-body">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>user category / user level</th>
                                        <th>category type</th>
                                        <th>service code</th>
                                        <th>fee type</th>
                                        <th>fee value</th>
                                        <th>fee cap</th>
                                        <th>actions</th>
                                    </tr>
                                </thead>
                                {!load && list?.feeProfiles?.length > 0 && (
                                    <tbody>
                                        {list.feeProfiles.map(
                                            (tr: any, i: number) => (
                                                <tr key={tr.id}>
                                                    <td>
                                                        {' '}
                                                        {15 * (1 - 1) + i + 1}
                                                    </td>

                                                    <td>{tr.userType}</td>
                                                    <td>{tr.description}</td>
                                                    <td>
                                                        {checkCategory(
                                                            tr.userType,
                                                            tr.categories,
                                                            tr.levels,
                                                            tr.userCategory,
                                                            tr.userLevel,
                                                        )}
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        {
                                                            tr.transactionServiceCode
                                                        }
                                                    </td>
                                                    <td>{tr.feeType}</td>
                                                    <td>{tr.feeValue}</td>
                                                    <td>{tr.feeCap}</td>
                                                    <td className="d-flex align-items-center">
                                                        <span>
                                                            <AiOutlineDelete />
                                                        </span>
                                                        <span
                                                            onClick={() => {
                                                                setDetails(tr);
                                                                setAdd(true);
                                                            }}>
                                                            {' '}
                                                            <GoPencil />
                                                        </span>
                                                        <span>
                                                            {' '}
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="flexSwitchCheckDefault"
                                                                />
                                                            </div>
                                                        </span>
                                                        <span>
                                                            <MdShare />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent
                open={add}
                title="Add Fee"
                size="lg"
                close={() => setAdd(false)}>
                <FeeForm
                    title={title}
                    setTitle={setTitle}
                    userType={userType}
                    setUserType={setUserType}
                    kycLevel={kycLevel}
                    setKycLevel={setKycLevel}
                    transactionCategory={transactionCategory}
                    setTransactionCategory={setTransactionCategory}
                    transactionServiceCode={transactionServiceCode}
                    setTransactionServiceCode={setTransactionServiceCode}
                    feeType={feeType}
                    setFeeType={setFeeType}
                    feeValue={feeValue}
                    setFeeValue={setFeeValue}
                    feeCap={feeCap}
                    setFeeCap={setFeeCap}
                    hasRange={hasRange}
                    setHasRange={setHasRange}
                    extraFee={extraFee}
                    setExtraFee={setExtraFee}
                    biller={biller}
                    setBiller={setBiller}
                    description={description}
                    setDescription={setDescription}
                    {...details}
                    serviceCodeDetails={serviceCodeDetails}
                    transactionCategoryDetails={transactionCategoryDetails}
                />
            </ModalComponent>
        </div>
    );
}

export default Fees;
