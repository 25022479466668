import React from 'react';

interface FaceVerificationDetailsProps {
    details?: any;
}

const FaceVerificationDetails: React.FC<FaceVerificationDetailsProps> = ({
    details,
}) => {
    const data = details?.face_verification_data?.data;

    // // Function to render value based on its type
    // const renderValue = (value: any) => {
    //     if (typeof value === 'object' && value !== null) {
    //         // If the value is an object, render it as a list of key-value pairs
    //         return (
    //             <div>
    //                 {Object.entries(value).map(([subKey, subValue]) => (
    //                     <div key={subKey} className="customer-details">
    //                         <div className="customer-details-content">
    //                             <span>{subKey}</span>
    //                             <strong>{renderValue(subValue)}</strong>
    //                         </div>
    //                     </div>
    //                 ))}
    //             </div>
    //         );
    //     }
    //     // Otherwise, render it as a string or number
    //     return value?.toString();
    // };

    return (
        <div className="row">
            <div className="col-lg-6 customer-details">
                {data ? (
                    Object.entries(data).map(([key, value]: any) => {
                        if (
                            key === 'bvn_check' ||
                            (key === 'face_verification_check' &&
                                typeof value === 'object')
                        ) {
                            return null;
                            // return (
                            //     <div
                            //         key={key}
                            //         className="customer-details-content">
                            //         <h4>{key}</h4>
                            //         {renderValue(value)}
                            //     </div>
                            // );
                        }

                        if (key === 'imageUrl' || key === 'photo') {
                            const imageUrl =
                                key === 'imageUrl'
                                    ? value
                                    : `data:image/jpeg;base64,${value}`;
                            return (
                                <div
                                    key={key}
                                    className="customer-details-veri">
                                    <span className="left">{key}</span>
                                    <img
                                        className="right"
                                        src={imageUrl}
                                        alt={key}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                        }}
                                    />
                                </div>
                            );
                        }

                        if (key === 'bvn') {
                            return (
                                <div
                                    key={key}
                                    className="customer-details-veri">
                                    <span className="left">{key}</span>
                                    <strong className="right">
                                        {value?.substring(0, 4)}*******
                                    </strong>
                                </div>
                            );
                        }

                        return (
                            <div key={key} className="customer-details-veri">
                                <span className="left">{key}</span>
                                <strong className="right">{value} </strong>
                            </div>
                        );
                    })
                ) : (
                    <div>No Data</div>
                )}
            </div>
            <div className="col-lg-6"></div>
        </div>
    );
};

export default FaceVerificationDetails;
