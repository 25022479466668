import React, {useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import {formatCurrency} from '../../../utils/numberFormat';
import BreadCrumbTitle from '../../../component/BreadCrumbTitle';
import FullLogo from "../../../assets/images/fulllogo.svg";
function PrintDetails() {
    const details = useLocation().state;
    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const detail = details?.additionalInfo;

    return (
        <>
        <BreadCrumbTitle
                title="Transaction Details"
        />
        <div className="main-content" id="panel">
            <div className="container" ref={componentRef}>
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                        <div className="print-reciept mt-3">
                            <div
                                style={{
                                    border: '1px solid rgba(0,0,0,0.4)',
                                    padding: '40px',
                                    background: '#fff',
                                }}
                                className="shadow-sm">
                                {detail && (
                                    <>
                                        <div className="text-center">
                                            <img
                                                src={FullLogo}
                                                alt="GetriPay"
                                                style={{height: '40px'}}
                                            />
                                            <p className="small">
                                                {details?.agent?.businessName}
                                            </p>
                                            <p className="small">
                                                {detail?.agent?.businessAddress}
                                            </p>
                                        </div>
                                        <div className="print-content mt-4">
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <p>Terminal:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>
                                                        {
                                                            detail?.terminal
                                                                ?.serialNo
                                                        }
                                                    </p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Type:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>
                                                        {detail.aid
                                                            ? 'Withdrawal'
                                                            : '--'}
                                                    </p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Pan:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>{detail.pan}</p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Card Type:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>{detail.cardType}</p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Expiry:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>{detail.expiryDate}</p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Name:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>{detail.cardName}</p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Amount:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>
                                                        {formatCurrency(
                                                            details.transactionAmount,
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>Response Code:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>
                                                        {detail.responseCode ||
                                                            details?.institutionTxnRef}
                                                    </p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>RRN:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>{detail.rrn}</p>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <p>STAN:</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p>{detail.stan}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3 mb-5 text-center">
                <button className="main-btn" onClick={handlePrint}>
                    Print Receipt
                </button>
            </div>
        </div> </>
    );
}

export default PrintDetails;
