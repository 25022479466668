 
import { authHeader} from "../../../utils/headers";
import { apiRequest } from "../../../utils/axiosInstance";

const getUserStats = async (startDate: any, endDate: any, token: string) => {
	const { data } = await  apiRequest('baseUrl').get(
		`/user/all_count_for_users/${startDate}/${endDate}`,
		{
			headers: authHeader(token),
		}
	);
	return data.data;
};

const getTransactionStats = async (
	id: string,
	startDate: any,
	endDate: any,
	token: string
) => {
	const { data } = await apiRequest('base').get(
		`https://api.getripay.com/api/terminal//transaction/get_transaction_report/${id}?startDate=${startDate}&endDate=${endDate}&userType=admin`,
		{
			headers: authHeader(token),
		}
	);
	return data.data;
};

const basicService = {
	getUserStats,
	getTransactionStats,
};

export default basicService;
