import {apiRequest} from '../../../utils/axiosInstance';
import {authHeader} from '../../../utils/headers';

const getLoginHistories = async (token: string,filters:string) => {
    const {data} = await apiRequest('baseUrl3Admin').get(`/login_histories${filters}`, {
        headers: authHeader(token),
    });
    return data.data;
};

const loginHistoryService = {
    getLoginHistories,
};

export default loginHistoryService;
