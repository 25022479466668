import React, {useState} from 'react';
import {useAppSelector} from '../../redux/hooks';
import {TextInput} from '../BasicInputs';
import {displayError} from '../../utils/errors';
import {Spinner} from 'react-bootstrap';
import customerServices from '../../redux/features/customer/customerService';

interface props {
    close: () => void;
    setOtpModal: (arg:boolean) => void;
    bvn:string;
    setBvn:(arg:any) => void;
    auth:string;
    address:string;
    email:string
    username:string;
    phoneNumber:string;
    setAuth:(arg:any) => void;
    setAddress:(arg:any) => void;
    setEmail:(arg:any) => void;
    setUsername:(arg:any) => void;
    setPhoneNumber:(arg:any) => void;
}

function CreateCustomer({
    close,
    setOtpModal,
    bvn,
    setBvn,
    auth,
    address,
    email,
    username,
    phoneNumber,
    setAuth,
    setAddress,
    setEmail,
    setUsername,
    setPhoneNumber,

}: props) {
    const {token, details: data} = useAppSelector(state => state.auth);

    const [isShow, setIsShow] = useState(true);
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    

    const verifyBvn = async (val: string) => {
        setAuth(val);
        if (val.length === 6) {
            setLoad(true);
            let payload = {bvn: bvn, google_auth_code: val};
            try {
                await customerServices.verifyBVN(token, payload, {
                    aes_key: data.aes_key,
                    aes_iv: data.aes_iv,
                });
                setOpen(true);
                setLoad(false);
                setIsShow(false);
                setAuth('');
            } catch (err) {
                displayError(err, true);
                setLoad(false);
            }
        } else {
        }
    };

    return (
        <div className="row">
            <div className="mb-3">
                <TextInput
                    val={bvn}
                    changeVal={setBvn}
                    label="Enter BVN"
                    type="number"
                    placeholder="Bvn Number"
                />
            </div>
            {isShow && (
                <div className="mb-3">
                    <TextInput
                        val={auth}
                        changeVal={verifyBvn}
                        label="Google Auth Code"
                        type="number"
                        placeholder="Enter your Google Auth"
                    />
                </div>
            )}

            {load && (
                <div>
                    <Spinner />
                </div>
            )}

            {open && (
                <>
                    <div className="mb-3">
                        <TextInput
                            val={username}
                            changeVal={setUsername}
                            label="Username"
                            type="text"
                            placeholder="Enter Username"
                        />
                    </div>
                    <div className="mb-3">
                        <TextInput
                            val={email}
                            changeVal={setEmail}
                            label="Enter Email"
                            type="email"
                            placeholder="Enter Email"
                        />
                    </div>
                    <div className="mb-3">
                        <TextInput
                            val={phoneNumber}
                            changeVal={setPhoneNumber}
                            label="Phone Number"
                            type="number"
                            placeholder="Enter phone Number"
                        />
                    </div>
                    <div className="mb-3">
                        <TextInput
                            val={address}
                            changeVal={setAddress}
                            label="Address"
                            type="text"
                            placeholder="Enter Address"
                        />
                    </div>
                    <div className="col-lg-12 text-center">
                        <button
                            onClick={() => {
                                setOtpModal(true);
                                close();
                            }}
                            className="main-btn">
                            { 'Create New Customer'}
                        </button>
                    </div>
                </>
            )}
           
        </div>
    );
}

export default CreateCustomer;
