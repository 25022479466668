import { useState } from "react";
import { displayError } from "../../utils/errors";
import authService from "../../redux/features/auth/authService";
import BasicLoad from "../../component/Loaders/BasicLoad";
import GoogleAuth from "../../component/Auth/GoogleAuth";

const Login = () => {
	const [load, setLoad] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginResponse, setLoginResponse] = useState<any>({});

	const loginHandler = async (e: any) => {
		e.preventDefault();
		try {
			setLoad(true);
			let res = await authService.login({ email, password });
			setLoad(false);
			setLoginResponse(res);
		} catch (err) {
			setLoad(false);
			displayError(err, true);
			console.log(err);
		}
	};

	return (
		<div className="form">
			{!loginResponse?.login_temp_token && (
				<h6>Welcome, Admin. Please Login.</h6>
			)}

			{loginResponse?.login_temp_token ? (
				<GoogleAuth email={email} resp={loginResponse} />
			) : (
				<form onSubmit={loginHandler}>
					<label className="label">Username/Email:</label>
					<input
						type="text"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						disabled={load}
						required
					/>
					<label className="label">Password:</label>
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						disabled={load}
						required
					/>
					<div className="text-center">
						{load ? (
							<BasicLoad />
						) : (
							<button disabled={load} type="submit">
								Login
							</button>
						)}
					</div>
				</form>
			)}
		</div>
	);
};

export default Login;
