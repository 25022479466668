import React, {useState} from 'react';
import {SelectInput} from '../BasicInputs';
import {useAppSelector} from '../../redux/hooks';
import terminalService from '../../redux/features/terminals/terminalService';
import {displayError} from '../../utils/errors';
interface props {
    terminalId: any;
    features: any;
    refresh: () => void;
}
function Features({terminalId, features, refresh}: props) {
    const {token, details} = useAppSelector(state => state.auth);

    const [feature, setFeature] = useState('');
    const [load, setLoad] = useState(false);
    const checkifChecked = (feature: any) => {
        const find = features.find((f: any) => f.feature === feature);
        return find ? false : true;
    };

    const handleSubmit = async (e: any) => {
        let payload = {
            terminalId,
            feature,
        };
        try {
            let url = checkifChecked(feature)
                ? 'disable-feature'
                : 'enable-feature';
            setLoad(true);
            const res = await terminalService.setFeatures(token, url, payload);
            if (res) {
                alert('successful');
                refresh();
            }
            setLoad(false);
        } catch (err) {
            setLoad(false);
            displayError(err, true);
        }
    };
    return (
        <div className="row">
            {[
                'Withdrawal',
                'Transfer',
                'Bills',
                'CardBalance',
                'Balance',
                'GAPrint',
            ].map(feature => (
                <div className="d-flex align-items-center" key={feature}>
                    <input
                        type="checkbox"
                        checked={checkifChecked(feature)}
                        readOnly
                    />
                    <span className="ml-2">{feature}</span>
                </div>
            ))}
            <div className="body-grid">
                <div className="box box9">
                    <label>Select a feature to Enable or Disable</label>
                    <SelectInput
                        val={feature}
                        changeVal={setFeature}
                        placeholder="Select"
                        required
                        disabled={load}
                        option={[
                            {label: 'Withdrawal', value: 'Withdrawal'},
                            {label: 'Transfer', value: 'Transfer'},
                            {label: 'Bills', value: 'Bills'},
                            {label: 'CardBalance', value: 'CardBalance'},
                            {label: 'Balance', value: 'Balance'},
                            {label: 'GAPrint', value: 'GAPrint'},
                        ]}
                    />
                </div>
                {feature && (
                    <div className="box box9">
                        <div className="text-center">
                            <button
                                disabled={load}
                                type="button"
                                className="btn btn-primary my-4"
                                onClick={handleSubmit}>
                                {load
                                    ? 'Hold On...'
                                    : checkifChecked(feature)
                                    ? 'Disable'
                                    : 'Enable'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Features;
