import React from 'react'
import { SelectInput, TextInput } from '../../../../component/BasicInputs';

interface props{
    user:string;
    setUser:(arg:any) => void;
    userType:string;
    setUserType:(arg:any) => void;
    userName:string;
    setUserName:(arg:any) => void;
    filter:()=>void;
}
function AddBeneficiary({
    user,
    setUser,
    userType,
    setUserType,
    userName,
    setUserName,
    filter
}:props) {
  return (
    <div className='row'>
         <div className="col mb-3">
                <SelectInput
                    option={[
                        {label: 'User', value: 'user'},
                        {label: 'Pathner', value: 'pathner'},
                    ]}
                    val={user}
                    changeVal={setUser}
                    label="User Category"
                    placeholder="Select"
                    required
                />
            </div>
         <div className="col-lg-12 mb-3">
                <TextInput
                    val={userType}
                    changeVal={setUserType}
                    label="User Type"
                    type="text"
                    placeholder="User type"
                    required
                />
            </div>
            <div className="col-lg-12 mb-3">
                <TextInput
                    val={userName}
                    changeVal={setUserName}
                    label="User Name"
                    type="text"
                    placeholder="User name"
                    required
                />
            </div>
           
            <div className="col-lg-12 text-center">
                <button onClick={filter} className="main-btn">Create</button>
            </div>

    </div>
  )
}

export default AddBeneficiary